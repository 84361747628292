<template>

  <base-edit :title="user.name"
             :toptitle="$t('settings.account.profile.edit')"
             name="settings-account-edit"
             :width="400"
             v-bind="$attrs"
             v-on="$listeners">
    <settings-account-form :user="user" type="edit"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import SettingsAccountForm from '@/components/settings/account/SettingsAccountForm'

export default {
  name: 'SettingsAccountEdit',
  components: {
    BaseEdit,
    SettingsAccountForm
  },
  props: {
    user: Object
  }
}
</script>
