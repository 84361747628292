<template>

  <section class="wrapper-login">

    <div class="logo">
      <img src="/static/konnektor_wortmarke_white.svg" alt=""/>
    </div>

    <div class="top">
      <div class="title">Konto bestätigen</div>
    </div>

    <div class="card">
      <form @submit.prevent="submit">
        <BaseInput v-model="password"
                   icon="key"
                   placeholder="Passwort festlegen"
                   type="password"
                   autocomplete="new-password"/>

        <ButtonText :disabled="!password"
                    :loading="loading"
                    label="Bestätigen"
                    type="submit"/>
      </form>
    </div>

  </section>

</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import BaseInput from '@/components/base/input/BaseInput'

export default {
  name: 'RegisterConfirm',
  components: { BaseInput },
  data () {
    return {
      password: null,
      loading: false,
      success: null
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        await axios.post('/register/confirm', {
          emailConfirmationToken: this.$route.params.token,
          plainPassword: this.password
        })
        this.$toasted.success('Account bestätigt!', { icon: 'fas fa-check-circle' })
        this.loading = false
        this.success = true
        await this.$router.push({ name: 'login' })
      } catch (error) {
        this.loading = false
        this.$toasted.error(error.response.data, { icon: 'fas fa-exclamation-circle' })
      }
    }
  },
  computed: {
    ...mapState(['app', 'auth'])
  }
}
</script>
