<template>

  <base-list-card-item icon=""
                  :title="source.name"
                  :subtitle="subtitle(source.tweet_volume)">
    <template #hover>
      <ButtonText label="Posting" icon="plus"
                  @click="$vfm.show({component: 'DashboardCreatePostingModal', bind: {subtitle: source.name, comment: comment}})"/>
    </template>
  </base-list-card-item>

</template>

<script>
import BaseListCardItem from '@/components/base/list/BaseListCardItem'

export default {
  name: 'DashboardTrendItem',
  components: { BaseListCardItem },
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    subtitle (tweetVolume) {
      return tweetVolume ? `${tweetVolume.toLocaleString()} Tweets` : null
    }
  },
  computed: {
    comment () {
      return `Aktueller Trend: ${this.source.name}`
    }
  }
}
</script>
