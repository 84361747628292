<template>
  <base-bar-item :icon="icon"
                 :title="title"
                 toptitle="Konnektor"/>
</template>

<script>
import { mapGetters } from 'vuex'
import { startCase } from 'lodash'
import BaseBarItem from '@/components/base/bar/BaseBarItem'
import moment from 'moment'

export default {
  name: 'DashboardTopUser',
  components: { BaseBarItem },
  computed: {
    icon () {
      if (moment().hour() < 12) {
        return 'coffee'
      } else if (moment().hour() < 17) {
        return 'user'
      } else {
        return 'moon'
      }
    },
    title () {
      if (moment().hour() < 12) {
        return this.$i18n.t('dashboard.hello.morning', { username: startCase(this.currentUser.name) })
      } else if (moment().hour() < 17) {
        return this.$i18n.t('dashboard.hello.day', { username: startCase(this.currentUser.name) })
      } else {
        return this.$i18n.t('dashboard.hello.evening', { username: startCase(this.currentUser.name) })
      }
    },
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  }
}
</script>
