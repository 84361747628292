import createCrudModule from '@/utils/createCrudModule'
import _ from 'lodash'

export default createCrudModule({
  resource: 'workspaces',
  urlRoot: '/workspaces',
  state: {
    filter: {
      search: '',
      sortBy: { icon: 'calendar', label: 'Erstellt vor', value: (o) => o.createdAt || '', order: 'desc' }
    }
  },
  getters: {
    filter: state => state.filter,
    filtered: state => {
      return _.orderBy(Object.values(state.entities), state.filter.sortBy.value, state.filter.sortBy.order)
        .filter(workspace => workspace.name.toLowerCase().indexOf(state.filter.search.toLowerCase()) > -1)
    }
  }
})
