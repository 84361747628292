<template>
  <base-form>
    <template #form>
      <BaseInput v-model="form.name"
                 :placeholder="$t('settings.workspace.info.name')"
                 icon="building"
                 type="text"/>

      <label>{{ $t('settings.workspace.info.logo') }}</label>
      <vue-dropzone id="dropzone"
                    ref="myVueDropzone"
                    :options="dropzoneOptions"
                    :useCustomSlot="true"
                    @vdropzone-mounted="dropzoneMounted"
                    @vdropzone-success="success">
        <font-awesome-icon icon="cloud-upload-alt"/>
        {{ $t('users.form.pictureMessage') }}
      </vue-dropzone>
    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  :label="$t('button.save')"
                  type="submit"
                  @click="update"/>
    </template>

  </base-form>
</template>

<script>
import BaseForm from '@/components/base/form/BaseForm'
import BaseInput from '@/components/base/input/BaseInput'
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'SettingsWorkspaceForm',
  components: {
    BaseForm,
    BaseInput,
    VueDropzone: vue2Dropzone
  },
  props: {
    type: String,
    workspace: Object
  },
  data () {
    return {
      form: {},
      updating: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/workspaces/' + this.workspace.id + '/logo',
        acceptedFiles: 'image/jpeg, image/png',
        maxFiles: 1,
        autoProcessQueue: false,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': '',
          'X-Requested-With': ''
        },
        init () {
          this.on('addedfiles', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
          this.on('maxfilesexceeded', function (file) {
            this.removeAllFiles()
            this.addFile(file)
          })
        }
      }

    }
  },
  created () {
    this.form = cloneDeep(this.workspace)
  },
  methods: {
    async update () {
      this.updating = true
      if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
        this.$refs.myVueDropzone.processQueue()
      } else {
        await this.updateWorkspace({
          id: this.form.id,
          data: this.form
        })
        this.$vfm.hide('settings-workspace-edit')
        this.$toasted.success('Workspace gespeichert', { icon: 'fas fa-save' })
        this.updating = false
      }
    },
    dropzoneMounted () {
      if (this.form.logoName) {
        this.$refs.myVueDropzone.manuallyAddFile({
          name: this.form.logoName,
          type: this.form.logoMimeType,
          size: this.form.logoSize
        }, process.env.VUE_APP_API_URL + this.form.logoUrl)
      }
    },
    async success (file, response) {
      this.$vfm.hide('settings-workspace-edit')
      this.$toasted.success('Workspace gespeichert', { icon: 'fas fa-save' })
      this.updating = false
      await this.getWorkspace({ id: this.form.id })
    },
    ...mapActions({
      getWorkspace: 'workspaces/fetchSingle',
      updateWorkspace: 'workspaces/replace'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.name)
    }
  }
}
</script>
