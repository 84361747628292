import axios from 'axios'

export default {
  namespaced: true,
  state: {
    darkmodeAppSetting: localStorage.getItem('darkmode') ? parseInt(localStorage.getItem('darkmode')) : null,
    darkmodeSystemSetting: window.matchMedia('(prefers-color-scheme: dark)').matches,
    languageAppSetting: localStorage.getItem('language') ? localStorage.getItem('language') : null,
    languageSystemSetting: navigator.language.split('-')[0],
    weather: {},
    version: 'Version 2.0'
  },
  mutations: {
    changeDarkmodeAppSetting: (state, payload) => {
      state.darkmodeAppSetting = payload
    },
    changeDarkmodeSystemSetting: (state, payload) => {
      state.darkmodeSystemSetting = payload
    },
    changeLanguageAppSetting: (state, payload) => {
      state.languageAppSetting = payload
    },
    weatherLoaded: (state, payload) => {
      state.weather = payload.data
    }
  },
  actions: {
    changeLanguageAppSetting: ({ commit }, language) => {
      commit('changeLanguageAppSetting', language)
      if (language !== null) {
        localStorage.setItem('language', language)
      } else {
        localStorage.removeItem('language')
      }
    },
    changeDarkmodeAppSetting: ({ commit }, status) => {
      commit('changeDarkmodeAppSetting', status)
      if (status !== null) {
        localStorage.setItem('darkmode', status)
      } else {
        localStorage.removeItem('darkmode')
      }
    },
    async loadWeather ({ commit, rootGetters }) {
      try {
        const responseIpStack = await axios.get('https://ipinfo.io/' + rootGetters['auth/authUser'].ip + '/?token=1ee58b6410688b', {
          transformRequest: [(data, headers) => {
            delete headers.common.Authorization
          }]
        })
        if (responseIpStack.data.loc) {
          const latitude = responseIpStack.data.loc.split(',')[0]
          const longitude = responseIpStack.data.loc.split(',')[1]
          const responseWeather = await axios.get('https://api.openweathermap.org/data/2.5/weather?lat=' + latitude + '&lon=' + longitude + '&lang=de&units=metric&APPID=6647c186418a8cedcab16b2b5851f4f4', {
            transformRequest: [(data, headers) => {
              delete headers.common.Authorization
            }]
          })
          commit('weatherLoaded', responseWeather)
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  getters: {
    darkmodeAppSetting: state => state.darkmodeAppSetting,
    darkmodeStatus: state => state.darkmodeAppSetting !== null ? !!parseInt(state.darkmodeAppSetting) : state.darkmodeSystemSetting,
    languageAppSetting: state => state.languageAppSetting,
    language: state => state.languageAppSetting !== null ? state.languageAppSetting : state.languageSystemSetting,
    weather: state => state.weather
  }
}
