<template>

  <vue-final-modal
    name="project-posting-item-restore-modal"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': '400px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs">
    <section class="modal">
      <div class="top">
        <div class="title">{{ $t('postings.restore.title') }}</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>
      {{ $t('postings.restore.text') }}
      <div class="action">
        <ButtonText :loading="restoring"
                    icon="trash-restore"
                    :label="$t('button.restore')"
                    type="button"
                    @click="restore"/>
      </div>
    </section>
  </vue-final-modal>

</template>

<script>
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectPostingItemRestoreModal',
  data () {
    return {
      form: {},
      restoring: false
    }
  },
  props: {
    posting: Object
  },
  created () {
    this.form = cloneDeep(this.posting)
  },
  methods: {
    async restore () {
      try {
        this.restoring = true
        this.form.isDraft = false
        await this.updatePosting({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-posting-item-restore-modal')
        this.$toasted.success('Posting wiederhergestellt', { icon: 'fas fa-trash-restore' })
        this.restoring = false
      } catch (error) {
        this.restoring = false
      }
    },
    ...mapActions({
      updatePosting: 'postings/replace'
    })
  }
}
</script>
