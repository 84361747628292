<template>

  <base-form>
    <template #form>

      <div v-if="type === 'create'">
        <clip-loader :loading="loading"
                     :color=" darkmode ? '#FFFFFF' : '#263238'"
                     size="20px">
        </clip-loader>
        <div id="payment-element"></div>
        <div v-if="error" id="payment-error">
          {{ error }}
        </div>
      </div>

      <div v-if="type === 'edit'">

        <div v-if="form.type ==='sepa_debit'">
          <BaseInput v-model="form.iban"
                     placeholder="IBAN"
                     icon="building"
                     type="text"
                     :disabled="type === 'edit'"/>
        </div>

        <div v-if="form.type ==='card'">
          <BaseInput v-model="form.cardNumber"
                     placeholder="Kartennummer"
                     icon="credit-card"
                     type="text"
                     :disabled="type === 'edit'"/>
          <BaseInput v-model.number="form.cardExpMonth"
                     placeholder="Monat (MM)"
                     icon="calendar-day"
                     type="text"
                     :maxlength="2"
                     :disabled="type === 'edit'"/>
          <BaseInput v-model.number="form.cardExpYear"
                     placeholder="Jahr (JJ)"
                     icon="calendar-day"
                     type="text"
                     :maxlength="2"
                     :disabled="type === 'edit'"/>
          <BaseInput v-model="form.cardCvc"
                     placeholder="Prüfziffer (***)"
                     icon="calendar-day"
                     type="text"
                     :maxlength="3"
                     :disabled="type === 'edit'"/>
        </div>

        <BaseCheckbox v-model="form.default"
                      id="active"
                      placeholder="Standard"/>
      </div>

    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :loading="removing"
                  :remove="true"
                  :save="true"
                  icon="trash"
                  :label="$t('button.remove')"
                  type="button"
                  @click="remove"/>

      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  :label="$t('button.save')"
                  type="submit"
                  @click="update"/>

      <ButtonText v-if="type === 'create'"
                  :disabled="checkForm"
                  :loading="creating"
                  icon="plus"
                  :label="$t('button.add')"
                  type="submit"
                  @click="create"/>
    </template>

  </base-form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseForm from '@/components/base/form/BaseForm'
import { cloneDeep } from 'lodash'
import BaseInput from '@/components/base/input/BaseInput'
import BaseCheckbox from '@/components/base/checkbox/BaseCheckbox'
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'SettingsPaymentMethodForm',
  props: {
    paymentMethod: Object,
    type: String
  },
  components: {
    BaseCheckbox,
    BaseInput,
    BaseForm
  },
  created () {
    this.form = cloneDeep(this.paymentMethod)
    if (this.type === 'create') this.initStripe()
  },
  data () {
    return {
      loading: true,
      form: null,
      creating: false,
      updating: false,
      removing: false,
      stripe: null,
      setupIntent: null,
      elements: null,
      error: null
    }
  },
  methods: {
    async initStripe () {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
      const response = await this.createPaymentSetupIntent({ data: {} })
      this.setupIntent = response.data
      this.elements = this.stripe.elements({
        clientSecret: this.setupIntent.clientSecret,
        appearance: {
          variables: {
            colorPrimary: this.darkmode ? '#FFFFFF' : '#009688',
            colorText: this.darkmode ? '#9E9E9E' : '#000000',
            colorBackground: this.darkmode ? '#1a1a1a' : '#FFFFFF',
            colorDanger: '#E57373',
            borderRadius: '4px'
          }
        }
      })
      const paymentElement = this.elements.create('payment')
      paymentElement.mount('#payment-element')
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: {
            return_url: process.env.VUE_APP_URL + '/einstellungen/abrechnung'
          },
          redirect: 'if_required'
        })
        this.$vfm.hide('settings-payment-method-create')
        this.$toasted.success('Zahlungsmethode hinzugefügt', { icon: 'fas fa-plus' })
        window.dataLayer.push({ event: 'add_payment_info' })
        this.getPaymentMethods()
        this.creating = false
      } catch (error) {
        console.log(error)
        this.error = error.message
        this.$toasted.error(error.response.data.detail)
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        await this.updatePaymentMethod({ id: this.form.id, data: this.form })
        this.$vfm.hide('settings-payment-method-edit')
        this.$toasted.success('Zahlungsmethode gespeichert', { icon: 'fas fa-save' })
        this.updating = false
        this.getPaymentMethods()
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deletePaymentMethod({ id: this.form.id })
        this.$vfm.hide('settings-payment-method-edit')
        this.$toasted.success('Zahlungsmethode entfernt', { icon: 'fas fa-trash-alt' })
        this.removing = false
        this.getPaymentMethods()
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.removing = false
      }
    },
    ...mapActions({
      getPaymentMethods: 'paymentMethods/fetchList',
      createPaymentMethod: 'paymentMethods/create',
      updatePaymentMethod: 'paymentMethods/replace',
      deletePaymentMethod: 'paymentMethods/destroy',
      createPaymentSetupIntent: 'paymentSetupIntents/create'
    })
  },
  computed: {
    checkForm () {
      return false
    },
    ...mapGetters({
      darkmode: 'app/darkmodeStatus'
    })
  }
}
</script>
