<template>
  <BaseButton :type="type"
              :icon="icon"
              :disabled="disabled"
              :label="label"
              :loading="loading"
              design="contained"
              :save="save"
              :remove="remove"
              @click="$emit('click')"/>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'

export default {
  name: 'ButtonContained',
  components: { BaseButton },
  props: {
    type: String,
    icon: [String, Array],
    disabled: Boolean,
    label: String,
    loading: Boolean,
    save: Boolean,
    remove: Boolean
  }
}
</script>

<style lang="scss">

.button.button-contained {
  background-color: $white;
  color: $text-color-1;
  box-shadow: $box-shadow-1;
}

.button.button-contained:hover {
  box-shadow: $box-shadow-2;
}

.button.button-contained:active {
  box-shadow: $box-shadow-3;
}

.button[disabled].button-contained {
  //box-shadow: none;
  //background: $teal-600;
  color: $text-color-3;
}

.button[disabled].button-contained svg {
  color: $text-color-3;
}

#app.dark {

  .button.button-contained {
    background-color: $dark-2;
  }

  .button.button-contained:hover {
    background-color: $dark-3;
  }

  .button.button-contained:active {
    background-color: $dark-4;
  }

}
</style>
