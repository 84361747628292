<template>

  <base-edit title="Slack"
             toptitle="Benachrichtigungen anpassen"
             name="settings-workspace-slack-notification-edit"
             :width="400"
             v-bind="$attrs"
             v-on="$listeners">
    <settings-workspace-slack-notification-form :workspace="workspace" type="edit"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import SettingsWorkspaceSlackNotificationForm
  from '@/components/settings/workspace/SettingsWorkspaceSlackNotificationForm.vue'

export default {
  name: 'SettingsWorkspaceSlackNotificationEdit',
  components: {
    SettingsWorkspaceSlackNotificationForm,
    BaseEdit
  },
  props: {
    workspace: Object
  }
}
</script>
