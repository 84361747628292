import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import store from '@/store/index'

Sentry.init({
  Vue,
  dsn: 'https://0a4f321edac84534bc69d15fb71f320c@sentry.flyingovation.com/2',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  attachProps: true,
  logErrors: true,
  trackComponents: true,
  environment: process.env.VUE_APP_MODE
})

Sentry.setUser({
  id: store.getters['auth/authUser'].id,
  username: store.getters['auth/authUser'].name,
  email: store.getters['auth/authUser'].email
})
