<template>
  <base-edit toptitle="Plattform bearbeiten"
             name="settings-workspace-platform-edit"
             :title="platform.label"
             :width="400"
             v-bind="$attrs"
             v-on="$listeners">
    <SettingsWorkspacePlatformForm :platform="platform"
                                   :index="index"
                                   type="edit"/>
  </base-edit>
</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import SettingsWorkspacePlatformForm from '@/components/settings/workspace/SettingsWorkspacePlatformForm'
export default {
  name: 'SettingsWorkspacePlatformEdit',
  components: { SettingsWorkspacePlatformForm, BaseEdit },
  props: {
    platform: Object,
    index: Number
  }
}
</script>
