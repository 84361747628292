<template>

  <div class="checkbox-wrapper">
    <input :id="id"
           :checked="checked"
           type="checkbox"
           @change="$emit('change', $event.target.checked)">
    <label :for="id">
      <font-awesome-icon class="checked" icon="check-square"/>
      <font-awesome-icon class="unchecked" :icon="['far', 'square']"/>
      <span>{{ placeholder }}</span>
      <div class="background"></div>
    </label>
  </div>

</template>

<script>
export default {
  name: 'BaseCheckbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    id: String,
    placeholder: String,
    icon: [String, Array],
    checked: Boolean
  }
}
</script>

<style lang="scss">

.checkbox-wrapper {
  position: relative;
  height: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  transition: all 0.3s ease;
}

.checkbox-wrapper svg {
  position: absolute;
  left: 0;
  margin-right: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.checkbox-wrapper span {
  position: absolute;
  left: 24px;
}

.checkbox-wrapper input {
  display: none;
}

.checkbox-wrapper input + label svg.checked {
  opacity: 0;
}

.checkbox-wrapper input + label svg.unchecked {
  opacity: 1;
}

.checkbox-wrapper input + label .background {
  position: absolute;
  top: -4px;
  left: -5px;
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
  background-color: rgba(black, 0.1);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
}

.checkbox-wrapper input:active + label .background {
  transform: scale(1.5);
  opacity: 1;
}

.checkbox-wrapper input:checked + label svg.checked {
  opacity: 1;
}

.checkbox-wrapper input:checked + label svg.unchecked {
  opacity: 0;
}

.checkbox-wrapper label {
  position: relative;
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  color: $text-color-1;
  user-select: none;
}

#app.dark {
  .checkbox-wrapper label {
    color: $gray-500;
  }
}

</style>
