<template>

  <base-create name="settings-payment-subscription-create"
               :title="`${subscription.title} abonnieren`"
               v-bind="$attrs"
               v-on="$listeners">
    <SettingsPaymentSubscriptionForm type="create" :subscription="subscription"/>
  </base-create>

</template>

<script>
import BaseCreate from '@/components/base/form/BaseCreate'
import SettingsPaymentSubscriptionForm from './SettingsPaymentSubscriptionForm.vue'

export default {
  name: 'SettingsPaymentSubscriptionCreate',
  components: { BaseCreate, SettingsPaymentSubscriptionForm },
  props: {
    subscription: Object
  }
}
</script>
