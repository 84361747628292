<template>

  <base-edit :title="workspace.name"
             :toptitle="$t('settings.workspace.info.edit')"
             name="settings-workspace-edit"
             :width="400"
             v-bind="$attrs"
             v-on="$listeners">
    <settings-workspace-form :workspace="workspace" type="edit"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import SettingsWorkspaceForm from '@/components/settings/workspace/SettingsWorkspaceForm'

export default {
  name: 'SettingsWorkspaceEdit',
  components: {
    BaseEdit,
    SettingsWorkspaceForm
  },
  props: {
    workspace: Object
  }
}
</script>
