<template>

  <base-bar-item :toptitle="weather.name ? darkModeInfo : null"
                 :title="$t('dashboard.design.title')"
                 icon="moon">
    <template #action>
      <vue-multiselect v-model="darkmodeModel"
                   :custom-label="label"
                   :options="[1, 0]"
                   :showLabels="false"
                   :placeholder="$t('dashboard.design.auto')"/>
    </template>
  </base-bar-item>

</template>

<script>
import BaseBarItem from '@/components/base/bar/BaseBarItem'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'DashboardTopDesign',
  components: { BaseBarItem },
  methods: {
    label (option) {
      switch (option) {
        case 1:
          return this.$t('dashboard.design.dark')
        case 0:
          return this.$t('dashboard.design.light')
      }
    },
    ...mapActions({
      changeDarkmodeAppSetting: 'app/changeDarkmodeAppSetting'
    })
  },
  computed: {
    darkmodeModel: {
      get () {
        return this.darkmodeAppSetting
      },
      set (status) {
        this.changeDarkmodeAppSetting(status)
      }
    },
    darkModeInfo () {
      const sunset = moment.unix(this.weather.sys.sunset)
      const sunrise = moment.unix(this.weather.sys.sunrise).add(1, 'days')
      const beforeSunset = moment().isBefore(sunset)
      const afterSunset = moment().isAfter(sunset)
      const beforeSunrise = moment().isBefore(sunrise)
      const afterSunrise = moment().isAfter(sunrise)

      if (beforeSunset && !this.darkmodeStatus) {
        return 'In ' + sunset.fromNow(true) + ' wird es dunkel'
      }
      if (afterSunset && !this.darkmodeStatus) {
        return 'Es ist bereits dunkel, jetzt ausprobieren!'
      }

      if (afterSunrise && this.darkmodeStatus) {
        return 'Es ist bereits hell, jetzt deaktivieren!'
      }

      if (beforeSunrise && this.darkmodeStatus) {
        return 'In ' + sunrise.fromNow(true) + ' wird es hell.'
      }

      return ''
    },
    ...mapGetters({
      weather: 'app/weather',
      darkmodeStatus: 'app/darkmodeStatus',
      darkmodeAppSetting: 'app/darkmodeAppSetting'
    })
  }
}
</script>
