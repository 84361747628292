<template>

  <base-create name="settings-payment-method-create"
               title="Zahlungsmethode hinzufügen"
               :width="400"
               v-bind="$attrs"
               v-on="$listeners">
    <SettingsPaymentMethodForm type="create" :paymentMethod="paymentMethod"/>
  </base-create>

</template>

<script>
import BaseCreate from '@/components/base/form/BaseCreate'
import SettingsPaymentMethodForm from './SettingsPaymentMethodForm.vue'

export default {
  name: 'SettingsPaymentMethodCreate',
  components: { BaseCreate, SettingsPaymentMethodForm },
  data () {
    return {
      paymentMethod: {
        type: 'sepa_debit',
        iban: null,
        cardNumber: null,
        cardExpMonth: null,
        cardExpYear: null,
        cardCvc: null,
        default: true,
        nextAction: null,
        promoCode: null
      }
    }
  }
}
</script>
