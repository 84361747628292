<template>

  <vue-final-modal
    name="project-posting-revision-restore-modal"
    :fit-parent="true"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': '400px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs">
    <section class="modal">
      <div class="top">
        <div class="title">{{ $t('revisions.restore.title') }}</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>
      {{ $t('revisions.restore.text') }}
      <div class="action">
        <ButtonText :loading="restoring"
                    icon="history"
                    :label="$t('button.restore')"
                    type="button"
                    @click="restore"/>
      </div>
    </section>
  </vue-final-modal>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProjectPostingRevisionRestoreModal',
  data () {
    return {
      restoring: false
    }
  },
  props: {
    posting: Object,
    revision: Object
  },
  methods: {
    async restore () {
      try {
        this.restoring = true
        await this.restoreRevision({ customUrl: '/revisions/' + this.revision.id + '/restore' })
        this.$vfm.hide('project-posting-revision-restore-modal')
        this.$toasted.success('Revision wurde wiederhergestellt', { icon: 'fas fa-history' })
        this.restoring = false
        await this.getPosting({ id: this.posting.id })
      } catch (error) {
        console.log(error.response)
      }
    },
    ...mapActions({
      restoreRevision: 'revisions/create',
      getPosting: 'postings/fetchSingle'
    })
  }
}
</script>
