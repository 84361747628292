<template>
  <section class="wrapper-bar">
    <div class="bar">
      <slot/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BaseBar'
}
</script>

<style lang="scss">

.wrapper-bar {
  position: sticky;
  z-index: 600;
  padding: 0 20px 10px 20px;
}

.wrapper-bar::before {
  content: "";
  background-color: $teal-500;
  width: 100%;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.wrapper-bar .bar {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  background-color: $white;
  border-radius: 8px;
  cursor: default;
  box-shadow: $box-shadow-1;
  transition: all 300ms ease;
  display: flex;
}

@media only screen and (max-width: 768px) {

  .wrapper-bar {
    position: relative;
    top: 0;
  }

  .wrapper-bar .bar {
    flex-flow: row wrap;
  }

}

#app.dark {
  .wrapper-bar::before {
    background-color: $dark-1;
  }

  .wrapper-bar .bar {
    background-color: $dark-2;
  }
}

</style>
