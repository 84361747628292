<template>

  <vue-final-modal
         name="project-posting-item-archive-modal"
          classes="modal-container"
          content-class="modal-content"
          :content-style="{'max-width': '400px'}"
          v-slot="{ close }"
          v-on="$listeners"
          v-bind="$attrs">
    <section class="modal">
      <div class="top">
        <div class="title">{{ $t('postings.archive.title') }}</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>
      {{ $t('postings.archive.text') }}
      <div class="action">
        <ButtonText :label="$t('button.archive')"
                    :loading="archiving"
                    :remove="true"
                    icon="trash-alt"
                    type="button"
                    @click="archive"/>
      </div>
    </section>
  </vue-final-modal>

</template>

<script>
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectPostingItemArchiveModal',
  data () {
    return {
      form: {},
      archiving: false
    }
  },
  props: {
    posting: Object
  },
  created () {
    this.form = cloneDeep(this.posting)
  },
  methods: {
    async archive () {
      try {
        this.archiving = true
        this.form.isDraft = true
        await this.updatePosting({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-posting-item-archive-modal')
        this.$toasted.success('Posting archiviert', { icon: 'fas fa-trash-alt' })
        this.archiving = false
      } catch (error) {
        this.archiving = false
      }
    },
    ...mapActions({
      updatePosting: 'postings/replace'
    })
  }
}
</script>
