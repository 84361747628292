<template>

  <base-edit toptitle="Zahlungsmethode bearbeiten"
             name="settings-payment-method-edit"
             :title="paymentMethodType"
             :width="400"
             v-bind="$attrs"
             v-on="$listeners">
    <SettingsPaymentMethodForm :paymentMethod="paymentMethod"
                               type="edit"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import SettingsPaymentMethodForm from './SettingsPaymentMethodForm.vue'

export default {
  name: 'SettingsPaymentMethodEdit',
  components: { BaseEdit, SettingsPaymentMethodForm },
  props: {
    paymentMethod: Object
  },
  computed: {
    paymentMethodType () {
      return this.paymentMethod.type === 'card' ? 'Kreditkarte' : 'SEPA-Lastschrift'
    }
  }
}
</script>
