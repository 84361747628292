<template>

  <body id="app" :class="{ dark : darkmodeStatus }">
  <router-view/>
  <modals-container></modals-container>
  </body>

</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  created () {
    if (window.matchMedia && (window.matchMedia('(prefers-color-scheme: dark)') || window.matchMedia('(prefers-color-scheme: light)'))) {
      try {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => e.matches && this.changeDarkmodeSystemSetting(1))
        window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', e => e.matches && this.changeDarkmodeSystemSetting(0))
      } catch (e1) {
        try {
          window.matchMedia('(prefers-color-scheme: dark)').addListener(e => e.matches && this.changeDarkmodeSystemSetting(1))
          window.matchMedia('(prefers-color-scheme: light)').addListener(e => e.matches && this.changeDarkmodeSystemSetting(0))
        } catch (e2) {
          console.error(e2)
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      changeDarkmodeSystemSetting: 'app/changeDarkmodeSystemSetting'
    })
  },
  computed: {
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
