import createCrudModule from '@/utils/createCrudModule'
import _ from 'lodash'
import i18n from '@/utils/i18n'

export default createCrudModule({
  resource: 'projects',
  urlRoot: '/projects',
  state: {
    filter: {
      search: '',
      workspaceUsers: [],
      sortBy: { label: i18n.t('sortBy.date'), value: 'releaseDate', icon: 'calendar-day' },
      sortOrder: 'desc',
      status: { icon: 'check-square', label: i18n.t('projects.top.active'), value: false }
    }
  },
  getters: {
    filter: state => state.filter,
    filtered: (state) => {
      return _.orderBy(Object.values(state.entities), state.filter.sortBy.value, state.filter.sortOrder)
        .filter(project => project.name.replace(/-/gm, ' ').indexOf(state.filter.search.toLowerCase()) > -1)
        .filter(project => !state.filter.workspaceUsers.length || state.filter.workspaceUsers.some(filterWorkspaceUser => {
          return project.workspaceUsers.some(workspaceUser => workspaceUser.id === filterWorkspaceUser.id)
        }))
        .filter(project => state.filter.status.value !== project.isActive)
    },
    personal: (state, getters, rootState, rootGetters) => {
      return getters.filtered.filter(project => project.managers.some(user => user.id === rootGetters['auth/authUser'].id))
    },
    byName: (state) => name => {
      return Object.values(state.entities).find(project => project.name === name)
    },
    byIri: (state) => iri => {
      const name = iri.split('/')[2]
      return Object.values(state.entities).find(project => project.name === name)
    },
    active: (state, getters) => {
      return getters.filtered.filter(project => project.isActive)
    },
    archived: (state, getters) => {
      return getters.filtered.filter(project => !project.isActive)
    },
    tourCompleted: (state, getters, rootState, rootGetters) => rootGetters['auth/currentUser'].tours.projects
  }
})
