<template>

  <base-list-card-item :icon="news.icon"
                  :title="news.title"
                  :subtitle="createdAt"
                  :content="news.text"
                  @click="$vfm.show({component: 'DashboardNewsViewModal', bind: { news: news }})">
  </base-list-card-item>

</template>

<script>
import BaseListCardItem from '@/components/base/list/BaseListCardItem'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardNewsItem',
  components: { BaseListCardItem },
  props: {
    news: Object
  },
  methods: {
    async createNews () {

    }
  },
  computed: {
    createdAt () {
      return moment(this.news.createdAt).fromNow()
    },
    subtitle () {
      return `${this.news.text.substr(0, 30)}...`
    },
    ...mapGetters({
      hasSuperAdminRole: 'auth/hasSuperAdminRole'
    })
  }
}
</script>
