<template>
  <base-form>
    <template #form>
      <BaseInput v-model="form.name"
                 placeholder="Name"
                 icon="building"
                 type="text"/>
      <BaseInput v-model="form.email"
                 placeholder="E-Mail"
                 icon="envelope"
                 type="email"/>
      <BaseInput v-model="form.plainPassword"
                 placeholder="Neues Passwort"
                 icon="key"
                 type="password"/>

      <label>{{ $t('users.form.picture') }}</label>
      <vue-dropzone id="dropzone"
                    ref="myVueDropzone"
                    :options="dropzoneOptions"
                    :useCustomSlot="true"
                    @vdropzone-mounted="dropzoneMounted"
                    @vdropzone-success="success">
        <font-awesome-icon icon="cloud-upload-alt"/>
        {{ $t('users.form.pictureMessage') }}
      </vue-dropzone>
    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  :label="$t('button.save')"
                  type="submit"
                  @click="update"/>
    </template>

  </base-form>
</template>

<script>
import BaseForm from '@/components/base/form/BaseForm'
import BaseInput from '@/components/base/input/BaseInput'
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'SettingsAccountForm',
  components: {
    BaseForm,
    BaseInput,
    VueDropzone: vue2Dropzone
  },
  props: {
    type: String,
    user: Object
  },
  data () {
    return {
      form: {},
      updating: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/users/' + this.user.id + '/picture',
        acceptedFiles: 'image/jpeg, image/png',
        maxFiles: 1,
        autoProcessQueue: false,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': '',
          'X-Requested-With': ''
        },
        init () {
          this.on('addedfiles', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
          this.on('maxfilesexceeded', function (file) {
            this.removeAllFiles()
            this.addFile(file)
          })
        }
      }

    }
  },
  created () {
    this.form = cloneDeep(this.user)
  },
  methods: {
    async update () {
      this.updating = true
      if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
        this.$refs.myVueDropzone.processQueue()
      } else {
        await this.updateUser({
          id: this.form.id,
          data: {
            ...this.form,
            workspace: '/workspaces/' + this.form.workspace.id
          }
        })
        this.$vfm.hide('settings-account-edit')
        this.$toasted.success('Profil gespeichert', { icon: 'fas fa-save' })
        this.updating = false
      }
    },
    dropzoneMounted () {
      if (this.form.pictureName) {
        this.$refs.myVueDropzone.manuallyAddFile({
          name: this.user.pictureName,
          type: this.user.pictureMimeType,
          size: this.user.pictureSize
        }, process.env.VUE_APP_API_URL + this.user.pictureUrl)
      }
    },
    async success (file, response) {
      this.$vfm.hide('settings-account-edit')
      this.$toasted.success('Benutzer gespeichert', { icon: 'fas fa-save' })
      this.updating = false
      await this.getUser({ id: this.form.id })
    },
    ...mapActions({
      getUser: 'users/fetchSingle',
      updateUser: 'users/replace'
    })
  },
  computed: {
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.form.email)
    },
    checkForm () {
      return !(this.validEmail)
    }
  }
}
</script>
