<template>
  <vue-final-modal
    name="project-posting-item-publish-modal"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': '800px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <section class="modal">
      <div class="top">
        <div class="title">Beitrag veröffentlichen</div>
        <font-awesome-icon icon="close" @click="close"/>
      </div>
      <form @submit.prevent="">

        <div class="group">
          <div class="group-left">
            <div class="group-title">
              <font-awesome-icon icon="share-alt"/>
              Kanal
            </div>
            <div class="group-subtitle">
              Auf welchem Kanal soll der Beitrag veröffentlicht werden?
            </div>
          </div>

          <div class="group-fields">
            <div class="multiselect-wrapper">
              <label>Kanal</label>
              <vue-multiselect
                v-model="form.channel"
                :hide-selected="false"
                :option-height="40"
                :options="filteredChannels"
                :showLabels="false"
                track-by="id"
                label="type">
                <template #placeholder>
                  <font-awesome-icon icon="share-alt"/>
                  <span>Kanäle</span>
                </template>
                <template #singleLabel="props">
                  <font-awesome-icon :icon="['fab', props.option.type.toLowerCase()]"/>
                  {{ props.option.name }}
                </template>
                <template #option="props">
                  <font-awesome-icon :icon="['fab', props.option.type.toLowerCase()]"/>
                  {{ props.option.name }}
                </template>
              </vue-multiselect>
            </div>
          </div>
        </div>

        <div class="group">
          <div class="group-left">
            <div class="group-title">
              <font-awesome-icon icon="ruler"/>
              Typ
            </div>
            <div class="group-subtitle">
              Welchen Typ soll der Beitrag haben?
            </div>
          </div>

          <div class="group-fields">
            <div class="multiselect-wrapper">
              <label>Typ</label>
              <vue-multiselect
                v-model="channelPostingType"
                :hide-selected="false"
                :option-height="40"
                :options="filteredTypes"
                :showLabels="false"
                track-by="type"
                label="label">
                <template #placeholder>
                  <font-awesome-icon icon="ruler"/>
                  <span>Typ</span>
                </template>
                <template #singleLabel="props">
                  <font-awesome-icon :icon="props.option.icon"/>
                  {{ props.option.label }}
                </template>
                <template #option="props">
                  <font-awesome-icon :icon="props.option.icon"/>
                  {{ props.option.label }}
                </template>
              </vue-multiselect>
            </div>
          </div>
        </div>

        <div class="group">
          <div class="group-left">
            <div class="group-title">
              <font-awesome-icon :icon="['fas', 'th-large']"/>
              Beitrag
            </div>
            <div class="group-subtitle">
              Es wurden ein paar Felder übernommen.
            </div>
          </div>

          <div class="group-fields">
            <BaseInput icon="calendar-day" :value="postingDatetime"/>
            <BaseTextarea icon="align-left" :value="latestRevision.text"/>
          </div>
        </div>

        <div class="group">
          <div class="group-left">
            <div class="group-title">
              <font-awesome-icon :icon="['fas', 'mobile']"/>
              Vorschau
            </div>
            <div class="group-subtitle">
              Schau dir deinen Beitrag in der Vorschau an.
            </div>
          </div>

          <div class="group-fields">

          </div>
        </div>

        <div class="action">
<!--          <ButtonText :disabled="checkForm"
                      :loading="creating"
                      :save="true"
                      icon="clock"
                      label="Planen"
                      type="submit"
                      @click="create"/>-->
          <ButtonText :disabled="checkForm"
                      :loading="creating"
                      :save="true"
                      icon="share-from-square"
                      label="Veröffentlichen"
                      type="submit"
                      @click="create"/>
        </div>
      </form>
    </section>

  </vue-final-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseInput from '@/components/base/input/BaseInput.vue'
import BaseTextarea from '@/components/base/BaseTextarea.vue'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectPostingItemPublishModal',
  components: { BaseInput, BaseTextarea },
  props: {
    posting: {
      type: Object,
      default () {
        return {
          id: null
        }
      }
    }
  },
  created () {
    this.form = cloneDeep(this.posting)

    // auto select the desired channel
    this.form.channel = this.project.channels.find(channel => channel.type.toLowerCase() === this.form.platform)

    // autoselect the type
    this.form.type = this.types[this.form.platform] ? this.types[this.form.platform][0].type : null
  },
  data () {
    return {
      types: {
        facebook: [
          {
            type: 'post',
            icon: 'square',
            label: 'Beitrag',
            fields: ['text', 'media']
          },
          {
            type: 'story',
            icon: 'portrait',
            label: 'Story',
            fields: ['text', 'media']
          }
        ],
        instagram: [
          {
            type: 'post',
            icon: 'square',
            label: 'Beitrag',
            fields: ['text', 'media']
          },
          {
            type: 'story',
            icon: 'portrait',
            label: 'Story',
            fields: ['text', 'media']
          }
        ],
        twitter: [
          {
            type: 'tweet',
            icon: 'square',
            label: 'Tweet',
            fields: ['text', 'media']
          }
        ],
        linkedin: [
          {
            type: 'post',
            icon: 'square',
            label: 'Beitrag',
            fields: ['text', 'media']
          }
        ],
        tiktok: [
          {
            type: 'video',
            icon: 'video',
            label: 'Video',
            fields: ['text', 'media']
          }
        ]
      },
      form: {},
      creating: false
    }
  },
  methods: {
    async create () {
      try {
        this.creating = true
        await this.publishPosting({ customUrl: '/postings/' + this.posting.id + '/publish', data: this.form })
        this.$toasted.success('Beitrag veröffentlicht', { icon: 'fas fa-share-from-square' })
        this.$vfm.hide('project-posting-item-publish-modal')
        this.creating = false
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.creating = false
      }
    },
    ...mapActions({
      publishPosting: 'postings/create'
    })
  },
  computed: {
    project () {
      return this.projectByName(this.$route.params.name)
    },
    filteredChannels () {
      return this.project.channels.filter(channel => channel.type.toLowerCase() === this.form.platform)
    },
    filteredTypes () {
      return this.types[this.form.platform] ?? []
    },
    channelPostingType: {
      get () {
        return this.types[this.form.platform] ? this.types[this.form.platform].find(type => type.type === this.form.type) : null
      },
      set (value) {
        this.form.type = value.type
      }
    },
    postingDatetime () {
      return moment(this.form.datetime).format('ddd DD. MMM yyyy hh:mm')
    },
    latestRevision () {
      return this.form.revisions.reduce((a, b) => a.createdAt > b.createdAt ? a : b, 0)
    },
    checkForm () {
      return !(this.form.channel && this.form.type)
    },
    ...mapGetters({
      projectByName: 'projects/byName'
    })
  }
}
</script>

<style scoped>

</style>
