import { render, staticRenderFns } from "./BaseListCardItem.vue?vue&type=template&id=4c0d2104"
import script from "./BaseListCardItem.vue?vue&type=script&lang=js"
export * from "./BaseListCardItem.vue?vue&type=script&lang=js"
import style0 from "./BaseListCardItem.vue?vue&type=style&index=0&id=4c0d2104&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports