<template>

  <section class="wrapper-login">

    <div class="logo">
      <img src="/static/konnektor_wortmarke_white.svg"/>
    </div>

    <div class="top">
      <div class="title">Passwort vergessen?</div>
    </div>

    <div class="card">
      <form v-if="!success" @submit.prevent="sendPasswordResetRequest">
        <BaseInput v-model="email"
                   icon="envelope"
                   :placeholder="$t('login.email')"
                   type="email"/>

        <ButtonText :disabled="!validEmail()"
                    :loading="loading"
                    label="Link anfordern"
                    type="submit"/>
      </form>

      <div v-if="success" class="message">
        <font-awesome-icon icon="envelope"/>
        <span>Wir haben dir eine E-Mail geschickt, um ein neues Passwort festzulegen.</span>
      </div>
    </div>

    <div class="footer">
      <router-link :to="{ name: 'login' }">Zurück zum Login</router-link>
    </div>

  </section>

</template>

<script>
import { mapState } from 'vuex'
import BaseInput from '@/components/base/input/BaseInput'
import axios from 'axios'

export default {
  name: 'ResetPasswordRequest',
  components: { BaseInput },
  data () {
    return {
      loading: false,
      success: null,
      email: ''
    }
  },
  methods: {
    async sendPasswordResetRequest () {
      try {
        this.loading = true
        await axios.post('/reset_password/request', {
          email: this.email
        })
        this.$toasted.success('E-Mail versendet!', { icon: 'fas fa-envelope' })
        this.loading = false
        this.success = true
      } catch (error) {
        this.loading = false
        this.$toasted.error(error.response.data, { icon: 'fas fa-exclamation-circle' })
      }
    },
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    }
  },
  computed: {
    ...mapState(['app', 'auth'])
  }
}
</script>
