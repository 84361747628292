<template>

  <section class="wrapper-login">
    <div class="logo">
      <img src="/static/konnektor_wortmarke_white.svg" alt=""/>
    </div>
    <div class="top">
      <div class="title">Anmelden</div>
    </div>
    <div class="card">
      <form @submit.prevent="sendForm">
        <BaseInput v-model="email"
                   icon="envelope"
                   :placeholder="$t('login.email')"
                   type="email"
                   autocomplete="email"/>
        <BaseInput v-model="password"
                   icon="key"
                   :placeholder="$t('login.password')"
                   type="password"
                   autocomplete="current-password"/>
        <ButtonText :disabled="!validEmail() || !password.length"
                    :loading="auth.pending"
                    :label="$t('button.login')"
                    type="submit"/>
      </form>
    </div>
    <div class="footer">
      <a :href="website">Zur Website</a> |
      <router-link :to="{ name: 'resetPasswordRequest' }">Passwort vergessen?</router-link>
    </div>
  </section>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseInput from '@/components/base/input/BaseInput'

export default {
  name: 'LoginView',
  components: { BaseInput },
  data () {
    return {
      email: '',
      password: '',
      website: process.env.VUE_APP_WEBSITE_URL
    }
  },
  methods: {
    async sendForm () {
      try {
        await this.login({
          email: this.email,
          password: this.password
        })
        if (this.$route.query.redirect) {
          await this.$router.push(this.$route.query.redirect)
        } else {
          await this.$router.push({ name: 'projects' })
        }
      } catch (error) {
        this.$toasted.error(error.response.data.message, { icon: 'fas fa-exclamation-circle' })
      }
    },
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    },
    ...mapActions({
      login: 'auth/login'
    })
  },
  computed: {
    ...mapState(['app', 'auth'])
  }
}
</script>
