<template>

  <div class="list-card-item">
    <div class="list-card-item-top">
      <div class="left">
        <div class="icon">
          <div class="skeleton skeleton-circle-small"></div>
        </div>
        <div class="text">
          <div class="toptitle">
            <div class="skeleton skeleton-toptitle"></div>
          </div>
          <div class="title">
            <div class="skeleton skeleton-title"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="skeleton skeleton-square-small"></div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'BaseListCardItemLoader'
}
</script>
