import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'revisions',
  urlRoot: '/revisions',
  getters: {
    byPostingId: state => id => {
      return Object.values(state.entities).filter(revision => revision.posting === '/postings/' + id)
    }
  }
})
