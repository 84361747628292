<template>

  <base-bar-item :icon="weather.name ? weatherIcon : 'compass'"
                 :subtitle="weather.name ? `Wind ${weather.wind.speed} km/h` : $t('dashboard.weather.subtitle')"
                 :title="weather.name ? `${Math.floor(weather.main.temp)}°C ${weather.weather[0].description}` : $t('dashboard.weather.title')"
                 :toptitle="weather.name ? weather.name : $t('dashboard.weather.toptitle')"/>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseBarItem from '@/components/base/bar/BaseBarItem'

export default {
  name: 'DashboardTopWeather',
  components: { BaseBarItem },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.weather.name || await this.loadWeather()
      this.loading = false
    },
    ...mapActions({
      loadWeather: 'app/loadWeather'
    })
  },
  computed: {
    weatherIcon () {
      const weatherIconInt = this.weather.weather[0].icon.substr(0, 2)
      switch (weatherIconInt) {
        case '01':
          return 'sun'
        case '02':
          return 'cloud-sun'
        case '03':
          return 'cloud'
        case '04':
          return 'cloud-meatball'
        case '09':
          return 'cloud-rain'
        case '10':
          return 'cloud-sun-rain'
        case '11':
          return 'bolt'
        case '13':
          return 'snowflake'
        case '50':
          return 'water'
        default:
          return ''
      }
    },
    ...mapGetters({
      weather: 'app/weather'
    })
  }
}
</script>
