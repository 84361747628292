<template>

  <base-edit :title="userName"
             name="users-edit"
             :toptitle="$t('users.form.edit')"
             v-bind="$attrs"
             v-on="$listeners">
    <users-form :workspaceUser="workspaceUser" type="edit"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import UsersForm from '@/components/users/UsersForm'
import _ from 'lodash'

export default {
  name: 'UsersEdit',
  components: {
    BaseEdit,
    UsersForm
  },
  props: {
    workspaceUser: Object
  },
  computed: {
    userName () {
      return this.workspaceUser.user ? _.startCase(this.workspaceUser.user.name) : null
    }
  }

}
</script>
