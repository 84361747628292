<template>

  <base-bar background>
    <DashboardBarUser/>
    <DashboardBarDatetime/>
    <DashboardBarWeather/>
    <DashboardBarDesign/>
  </base-bar>

</template>

<script>
import BaseBar from '@/components/base/bar/BaseBar'
import DashboardBarUser from '@/components/dashboard/bar/DashboardBarUser'
import DashboardBarDatetime from '@/components/dashboard/bar/DashboardBarDatetime'
import DashboardBarWeather from '@/components/dashboard/bar/DashboardBarWeather'
import DashboardBarDesign from '@/components/dashboard/bar/DashboardBarDesign'

export default {
  name: 'DashboardBar',
  components: {
    BaseBar,
    DashboardBarDesign,
    DashboardBarWeather,
    DashboardBarDatetime,
    DashboardBarUser
  }
}
</script>
