<template>

  <div class="list">

    <div class="top" v-if="title">
      <font-awesome-icon v-if="icon" :icon="icon"/>
      <div>
        <div class="title">
          {{ title }}
          <span>{{ count }}</span>
        </div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
      <div class="action">
        <slot name="action"/>
      </div>
    </div>

    <div v-if="$slots['default']">
      <slot/>
    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseList',
  props: {
    icon: String,
    title: String,
    subtitle: String,
    count: Number
  },
  computed: {
    color () {
      return this.darkmodeStatus ? '#FFFFFF' : '#263238'
    },
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>

<style lang="scss">

.list {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.list .top {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  height: 60px;
}

.list .top svg {
  margin-right: 10px;
  font-size: 1.2rem;
}

.list .top .title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
  color: $text-color-1
}

.list .top .title span {
  font-weight: 500;
  color: $text-color-2;
}

.list .top .subtitle {
  color: $text-color-2;
  font-size: 0.875rem;
}

.list .top .action {
  margin-left: auto;
}

.list .top .action svg {
  font-size: 0.875rem;
  margin-right: 5px;
}

#app.dark {

  .list .top .title {
    color: $white;
  }

  .list .top .title span {
    color: $gray-500;
  }

  .list .top .subtitle {
    color: $gray-500;
  }

}

</style>
