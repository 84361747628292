import axios from 'axios'

export default {
  namespaced: true,
  state: {
    trends: []
  },
  mutations: {
    trendsLoaded (state, payload) {
      state.trends = payload
    }
  },
  actions: {
    async fetchTrends ({ commit }) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/dashboard?id=656958')
        commit('trendsLoaded', response.data.trends)
      } catch (e) {
        console.error(e)
      }
    }
  },
  getters: {
    trends: state => state.trends
  }
}
