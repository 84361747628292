import axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'
import Vue from 'vue'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (!error.response) {
      Vue.toasted.error(error.message, { icon: 'fas fa-wifi' })
    }

    switch (error.response.status) {
      case 400:
        Vue.toasted.error(error.response.data.detail, { icon: 'fas fa-server' })
        break
      case 401:
        if (error.response.data.message === 'Expired JWT Token') {
          const response = await store.dispatch('auth/refreshToken')
          originalRequest.headers.Authorization = 'Bearer ' + response.data.token
          return axios(originalRequest)
        } else if (error.response.data.message === 'Invalid JWT Token') {
          await store.dispatch('auth/logout')
          await router.push({ name: 'login' })
        } else {
          Vue.toasted.error(error.response.data.message, { icon: 'fas fa-user-lock' })
        }
        break
      case 403:
        Vue.toasted.error(error.response.data.detail, { icon: 'fas fa-lock' })
        break
      case 404:
        Vue.toasted.error(error.response.data.detail, { icon: 'fas fa-search' })
        break
      case 500:
        Vue.toasted.error(error.response.data.detail, { icon: 'fas fa-server' })
        break
    }
    return Promise.reject(error)
  })

export default axios
