<template>
  <base-create name="settings-workspace-platform-create"
               title="Plattform hinzufügen"
               :width="400"
               v-bind="$attrs"
               v-on="$listeners">
    <SettingsWorkspacePlatformForm type="create"
                                   :platform="platform"/>
  </base-create>
</template>

<script>
import SettingsWorkspacePlatformForm from '@/components/settings/workspace/SettingsWorkspacePlatformForm'
import BaseCreate from '@/components/base/form/BaseCreate'

export default {
  name: 'SettingsWorkspacePlatformCreate',
  components: { BaseCreate, SettingsWorkspacePlatformForm },
  props: {
    platform: {
      type: Object,
      default () {
        return {
          label: null,
          icon: []
        }
      }
    }
  }
}
</script>
