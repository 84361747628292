<template>
  <div>

    <base-list title="Neuigkeiten" icon="newspaper" :count="news.length" :loading="loading">
      <!--      <template #action>-->
      <!--        <button-contained v-if="hasSuperAdminRole"-->
      <!--                          icon="plus"-->
      <!--                          label="Erstellen"-->
      <!--                          @click="createNews">-->
      <!--        </button-contained>-->
      <!--      </template>-->
      <base-list-card>
        <base-list-card-item v-if="!news.length && !loading"
                             icon="search"
                             title="Keine Neuigkeiten">
        </base-list-card-item>
      </base-list-card>

      <base-list-card v-for="item in sortedNews" :key="item.id">
        <dashboard-news-item :news="item"/>
      </base-list-card>
    </base-list>
  </div>

</template>

<script>
import BaseList from '@/components/base/list/BaseList'
import BaseListCardItem from '@/components/base/list/BaseListCardItem'
import { mapActions, mapGetters } from 'vuex'
import DashboardNewsItem from '@/components/dashboard/grid/news/DashboardNewsItem'
import { orderBy } from 'lodash'
import BaseListCard from '@/components/base/list/BaseListCard'

export default {
  name: 'DashboardNews',
  components: { BaseListCard, DashboardNewsItem, BaseListCardItem, BaseList },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    sortedNews () {
      return orderBy(this.news, 'createdAt', 'desc')
    },
    ...mapGetters({
      news: 'news/list',
      hasSuperAdminRole: 'auth/hasSuperAdminRole'
    })
  },
  methods: {
    async fetchData () {
      this.loading = true
      this.news.length || await this.getNews()
      this.loading = false
    },
    async createNews () {

    },
    ...mapActions({
      getNews: 'news/fetchList'
    })
  }
}
</script>
