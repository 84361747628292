<template>

  <base-edit :title="`Abonnement ${subscription.title } ändern`"
             name="settings-payment-subscription-edit"
             v-bind="$attrs"
             v-on="$listeners">
    <SettingsPaymentSubscriptionForm type="edit" :subscription="subscription"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import SettingsPaymentSubscriptionForm from './SettingsPaymentSubscriptionForm.vue'

export default {
  name: 'SettingsPaymentSubscriptionEdit',
  components: { BaseEdit, SettingsPaymentSubscriptionForm },
  props: {
    subscription: Object
  }
}
</script>
