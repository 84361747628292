import Vue from 'vue'
import axios from 'axios'

const FETCH_LIST_START = 'fetchListStart'
const FETCH_LIST_SUCCESS = 'fetchListSuccess'
const FETCH_SINGLE_START = 'fetchSingleStart'
const FETCH_SINGLE_SUCCESS = 'fetchSingleSuccess'
const CREATE_START = 'createStart'
const CREATE_SUCCESS = 'createSuccess'
const REPLACE_START = 'replaceStart'
const REPLACE_SUCCESS = 'replaceSuccess'
const DESTROY_START = 'destroyStart'
const DESTROY_SUCCESS = 'destroySuccess'

export default function createCrudModule ({ urlRoot, state = {}, mutations = {}, actions = {}, getters = {} }) {
  return {
    namespaced: true,
    state: {
      entities: {},
      list: [],
      ...state
    },
    mutations: {
      [FETCH_LIST_START] (state) {},
      [FETCH_LIST_SUCCESS] (state, payload) {
        payload.data.forEach(m => {
          Vue.set(state.entities, m.id, m)
          // state.entities[m.id] = m
        })
        state.list = payload.data.map(m => m.id)
      },
      [FETCH_SINGLE_START] (state) {},
      [FETCH_SINGLE_SUCCESS] (state, payload) {
        Vue.set(state.entities, payload.data.id, payload.data)
        // state.entities[payload.data.id] = payload.data
      },
      [CREATE_START] (state) {},
      [CREATE_SUCCESS] (state, payload) {
        Vue.set(state.entities, payload.data.id, payload.data)
        // state.entities[payload.data.id] = payload.data
        state.list.push(payload.data.id)
      },
      [REPLACE_START] (state) {},
      [REPLACE_SUCCESS] (state, payload) {
        Vue.set(state.entities, payload.data.id, payload.data)
        // state.entities[payload.data.id] = payload.data
      },
      [DESTROY_START] (state) {},
      [DESTROY_SUCCESS] (state, id) {
        const listIndex = state.list.indexOf(id)
        if (listIndex >= 0) {
          Vue.delete(state.list, listIndex)
          // state.list.splice(0, listIndex)
        }
        Vue.delete(state.entities, id)
        // delete state.entities.id
      },
      ...mutations
    },
    actions: {
      async fetchList ({ commit }, { customUrl } = {}) {
        try {
          commit(FETCH_LIST_START)
          const response = await axios.get(customUrl ?? urlRoot)
          commit(FETCH_LIST_SUCCESS, response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async fetchSingle ({ commit }, { id }) {
        try {
          commit(FETCH_SINGLE_START)
          const response = await axios.get(urlRoot + '/' + id)
          commit(FETCH_SINGLE_SUCCESS, response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async create ({ commit }, { data, customUrl }) {
        try {
          commit(CREATE_START)
          const response = await axios.post(customUrl ?? urlRoot, data)
          commit(CREATE_SUCCESS, response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async replace ({ commit }, { id, data }) {
        try {
          commit(REPLACE_START)
          const response = await axios.put(urlRoot + '/' + id, data)
          commit(REPLACE_SUCCESS, response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async destroy ({ commit }, { id }) {
        try {
          commit(DESTROY_START)
          const response = await axios.delete(urlRoot + '/' + id)
          commit(DESTROY_SUCCESS, id)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      ...actions
    },
    getters: {
      list (state) {
        return state.list.map(id => state.entities[id])
      },
      byId: state => id => {
        return state.entities[id]
      },
      ...getters
    }
  }
}
