<template>

  <div class="list-card-item">

    <div class="list-card-item-top">
      <div class="left">
        <div v-if="cover" class="cover">
          <img v-if="cover" :src="cover" alt=""/>
        </div>
        <div v-else-if="icon" class="icon">
          <font-awesome-icon :icon="icon"/>
        </div>
        <div class="text">
          <div v-if="toptitle" class="toptitle">{{ toptitle }}</div>
          <router-link v-if="link" :to="link" class="title">{{ title }}</router-link>
          <div v-else class="title">
            {{ title }}
            <span v-if="status" class="status">{{status}}</span>
          </div>
          <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
        </div>
      </div>
      <div class="hover" v-if="$slots['hover']">
        <slot name="hover"></slot>
      </div>
      <div class="right" v-if="$slots['default']">
        <slot></slot>
      </div>
    </div>
    <div v-if="content" v-html="content" class="list-card-item-content"></div>
    <div v-if="$slots['content']" class="list-card-item-content">
      <slot name="content"/>
    </div>

  </div>

</template>

<script>
export default {
  name: 'BaseListCardItem',
  props: {
    cover: String,
    icon: [String, Array],
    toptitle: String,
    title: [String, Number],
    status: String,
    subtitle: String,
    content: String,
    link: Object
  }
}
</script>

<style lang="scss">

.list-card-item {
  padding: 0 15px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  border-bottom: 1px solid $gray-200;
  transition: 0.3s all ease-out;
  min-height: 50px;
  background-color: $white;
}

.list-card-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.list-card-item:hover {
  background-color: $gray-50;
}

.list-card-item .list-card-item-top {
  display: flex;
  align-items: center;
}

.list-card-item .list-card-item-top .left {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.list-card-item .list-card-item-top .left .cover {
  width: 30px;
  margin-right: 10px;
}

.list-card-item .list-card-item-top .left .cover img {
  width: 100%;
  border-radius: 2px;
}

.list-card-item .list-card-item-top .left .icon {
  margin-right: 10px;
}

.list-card-item .list-card-item-top .left .icon svg {
  color: $text-color-1;
}

.list-card-item .list-card-item-top .left .text .toptitle {
  font-size: 0.875rem;
  color: $text-color-2;
  margin-bottom: 2px;
}

.list-card-item .list-card-item-top .left .text .title {
  font-weight: 500;
  font-size: 1rem;
}

.list-card-item .list-card-item-top .left .text .title span {
  display: inline-flex;
  color: $white;
  font-weight: 500;
  font-size: 0.875rem;
  background-color: $teal-500;
  border-radius: 4px;
  margin-left: 4px;
  padding: 2px 4px;
}

.list-card-item .list-card-item-top .left .text .subtitle {
  font-size: 0.875rem;
  color: $text-color-2;
  margin-top: 2px;
}

.list-card-item .list-card-item-top .right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.list-card-item .list-card-item-top .right .active {
  color: $white;
  font-weight: 500;
  font-size: 0.875rem;
  background-color: $teal-500;
  border-radius: 4px;
  padding: 2px 4px;
}

.list-card-item .list-card-item-top .hover {
  display: none;
  align-items: center;
  margin-left: auto;
  height: 40px
}

.list-card-item .list-card-item-content {
  line-height: 1.25;
  padding-bottom: 15px;
}

.list-card-item .list-card-item-content p {
  margin-bottom: 5px;
}

.list-card-item .list-card-item-content img {
  border-radius: 4px;
  border: 1px solid $gray-200;
  width: 100%;
}

.list-card-item .list-card-item-content a {
  display: inline-flex;
  font-weight: 500;
  border-radius: 4px;
  padding: 2px 4px;
  margin: 2px 0;
  margin-left: auto;
  color: $white;
  transition: 0.3s all ease;
  background: $teal-400;
  font-size: 0.875rem;
}

.list-card-item .list-card-item-content a:hover {
  background: $teal-500;
}

.list-card-item:hover .hover {
  display: flex;
}

.list-card-item:last-child {
  border-bottom: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#app.dark {

  .list-card-item {
    border-color: $dark-1;
    background-color: $dark-2;
  }

  .list-card-item:hover {
    background-color: $dark-3;
  }

  .list-card-item .left .text .toptitle,
  .list-card-item .left .text .subtitle {
    color: $gray-500;
  }

  .list-card-item .left .icon svg {
    color: $white;
  }

}

</style>
