<template>

  <vue-final-modal
    name="project-posting-item-media-modal"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': '400px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <section class="modal">
      <div class="top">
        <div class="title">Medium löschen</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>
      {{ question }}
      <div class="action">
        <ButtonText :loading="removing"
                    icon="trash-alt"
                    label="Löschen"
                    :remove="true"
                    type="button"
                    @click="accept"/>
      </div>
    </section>

  </vue-final-modal>

</template>

<script>
export default {
  name: 'ProjectPostingItemMediaModal',
  data () {
    return {
      media: {},
      removing: false
    }
  },
  props: {
    question: String
  },
  methods: {
    accept () {
      this.removing = true
      this.$emit('accepted')
      this.$vfm.hide('project-posting-item-media-modal')
      this.removing = false
    }
  }
}
</script>
