<template>

  <vue-final-modal
    :name="name"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': width + 'px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs"
    >
    <section class="modal">
      <div class="top">
        <div v-if="toptitle" class="toptitle">{{ toptitle }}</div>
        <div class="title">{{ title }}</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>
      <slot></slot>

    </section>
  </vue-final-modal>

</template>

<script>
export default {
  name: 'BaseEdit',
  props: {
    name: String,
    toptitle: String,
    title: String,
    width: {
      type: Number,
      default () {
        return 700
      }
    }
  }

}
</script>
