import router from '@/router'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refresh_token') || '',
    pending: false,
    error: null
  },
  mutations: {
    login: (state) => {
      state.pending = true
    },
    loginSuccess: (state, payload) => {
      state.token = payload.token
      state.refreshToken = payload.refresh_token
      state.pending = false
    },
    loginError: (state, payload) => {
      state.error = payload
      state.pending = false
    },
    refreshTokenSuccess: (state, payload) => {
      state.token = payload.token
      state.refreshToken = payload.refresh_token
      state.pending = false
    },
    refreshTokenError: (state, payload) => {
      state.error = payload
      state.pending = false
    },
    logout: (state) => {
      state.token = ''
      state.refreshToken = ''
    }
  },
  actions: {
    async login ({ commit }, creds) {
      commit('login')
      try {
        const response = await axios.post('/token', {
          email: creds.email,
          password: creds.password
        })
        commit('loginSuccess', response.data)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token
      } catch (error) {
        commit('loginError', error)
        return Promise.reject(error)
      }
    },
    async refreshToken ({ commit, dispatch }) {
      try {
        const response = await axios.post('/token/refresh', {
          refresh_token: localStorage.getItem('refresh_token')
        })
        commit('refreshTokenSuccess', response.data)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token
        return response
      } catch (error) {
        commit('refreshTokenError', error)
        dispatch('logout')
        await router.push({ name: 'login' })
        return Promise.reject(error)
      }
    },
    logout: ({ commit }) => {
      commit('reset', null, { root: true })
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      delete axios.defaults.headers.common.Authorization
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authUser: state => state.token ? jwtDecode(state.token) : '',
    currentUser: (state, getters, rootState, rootGetters) => rootGetters['users/byId'](getters.authUser.id),
    currentWorkspaceUser: (state, getters) => getters.currentUser && getters.currentUser.workspaceUsers.find(workspaceUser => workspaceUser.workspace.id === getters.currentUser.workspace.id),
    hasSuperAdminRole: (state, getters) => getters.authUser && getters.authUser.roles.some(role => role === 'ROLE_ADMIN'),
    hasAdminRole: (state, getters) => getters.currentWorkspaceUser && getters.currentWorkspaceUser.role >= 5,
    hasManagerRole: (state, getters) => getters.currentWorkspaceUser && getters.currentWorkspaceUser.role >= 4,
    hasEditorRole: (state, getters) => getters.currentWorkspaceUser && getters.currentWorkspaceUser.role >= 3,
    hasCustomerRole: (state, getters) => getters.currentWorkspaceUser && getters.currentWorkspaceUser.role === 2,
    hasGuestRole: (state, getters) => getters.currentWorkspaceUser && getters.currentWorkspaceUser.role === 1,
    hasBetaRole: (state, getters) => getters.authUser && getters.authUser.roles.some(role => role === 'ROLE_BETA')
  }
}
