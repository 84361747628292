import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'paymentMethods',
  urlRoot: '/payment_methods',
  getters: {
    bankAccounts: (state) => {
      return Object.values(state.entities).filter(paymentMethod => paymentMethod.type === 'sepa_debit')
    },
    creditCards: (state) => {
      return Object.values(state.entities).filter(paymentMethod => paymentMethod.type === 'card')
    }
  }
})
