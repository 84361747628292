<template>

  <base-create name="projects-create"
               :title="$t('projects.form.create')"
               v-bind="$attrs"
               v-on="$listeners">
    <projects-form type="create"/>
  </base-create>

</template>

<script>
import BaseCreate from '@/components/base/form/BaseCreate'
import ProjectsForm from './ProjectsForm.vue'

export default {
  name: 'ProjectsCreate',
  components: { BaseCreate, ProjectsForm }
}
</script>
