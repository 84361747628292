import store from '@/store/index'
import BackendView from '@/components/BackendView.vue'
import LoginView from '@/components/login/LoginView.vue'
import RegisterConfirm from '@/components/login/RegisterConfirm.vue'
import ResetPasswordRequest from '@/components/login/ResetPasswordRequest.vue'
import ResetPasswordConfirm from '@/components/login/ResetPasswordConfirm.vue'
import DashboardView from '@/components/dashboard/DashboardView.vue'
import NotFound from '@/components/utils/NotFound.vue'

const ProjectsView = () => import(/* webpackChunkName: "projects" */ '@/components/projects/ProjectsView.vue')
const ProjectView = () => import(/* webpackChunkName: "projects" */ '@/components/project/ProjectView.vue')
const ProjectPosting = () => import(/* webpackChunkName: "projects" */ '@/components/project/posting/ProjectPosting.vue')
const UsersView = () => import(/* webpackChunkName: "users" */ '@/components/users/UsersView')
const Admin = () => import(/* webpackChunkName: "admin" */ '@/components/admin/AdminView.vue')
const SettingsView = () => import(/* webpackChunkName: "settings" */ '@/components/settings/SettingsView.vue')
const SettingsAccount = () => import(/* webpackChunkName: "settings" */ '@/components/settings/account/SettingsAccount.vue')
const SettingsWorkspace = () => import(/* webpackChunkName: "settings" */ '@/components/settings/workspace/SettingsWorkspace.vue')
const SettingsPayment = () => import(/* webpackChunkName: "settings" */ '@/components/settings/payment/SettingsPayment.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isLoggedIn']) {
        next({ name: 'projects' })
      } else {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isLoggedIn']) {
        next({ name: 'projects' })
      } else {
        next()
      }
    }
  },
  {
    path: encodeURI('/konto-bestätigen/:token'),
    name: 'registerConfirm',
    component: RegisterConfirm
  },
  {
    path: '/passwort-vergessen',
    name: 'resetPasswordRequest',
    component: ResetPasswordRequest
  },
  {
    path: '/passwort-festlegen/:token',
    name: 'resetPasswordConfirm',
    component: ResetPasswordConfirm
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      store.dispatch('auth/logout')
      next({ name: 'login' })
    }
  },
  {
    path: '/',
    component: BackendView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        component: DashboardView,
        name: 'dashboard'
      },
      {
        path: 'projekte',
        component: ProjectsView,
        name: 'projects'
      },
      {
        path: 'projekte/:name',
        component: ProjectView,
        name: 'project',
        redirect: { name: 'projectPosting' },
        children: [
          {
            path: '',
            component: ProjectPosting,
            name: 'projectPosting'
          }
        ]
      },
      {
        path: 'benutzer',
        component: UsersView,
        name: 'users'
      },
      {
        path: 'admin',
        component: Admin,
        name: 'admin'
      },
      {
        path: 'einstellungen',
        component: SettingsView,
        name: 'settings',
        redirect: { name: 'settingsAccount' },
        children: [
          {
            path: 'konto',
            component: SettingsAccount,
            name: 'settingsAccount'
          },
          {
            path: 'workspace',
            component: SettingsWorkspace,
            name: 'settingsWorkspace'
          },
          {
            path: 'abrechnung',
            component: SettingsPayment,
            name: 'settingsPayment'
          }
        ]
      },
      {
        path: '404',
        component: NotFound,
        name: 'notFound'
      },
      {
        path: '*',
        component: NotFound
      }
    ]
  }
]

export default routes
