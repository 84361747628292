<template>

  <div class="loader-wrapper">
    <div class="loader">
      <svg class="loader-spinner" width="40px" height="40px" viewBox="0 0 66 66"
           xmlns="http://www.w3.org/2000/svg">
        <circle class="loader-path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33"
                r="30"></circle>
      </svg>
      <div class="loader-title">{{ title }}</div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'LoaderWrapper',
  props: ['title']
}
</script>

<style lang="scss">

.loader-wrapper {
  position: fixed;
  width: 200px;
  height: 70px;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -35px;
  z-index: 4500;
  /*display: none;*/
}

.loader {
  text-align: center;
  margin: 0 auto;
}

.loader .loader-spinner {
  animation: loader-rotator 1.4s linear infinite;
}

@keyframes loader-rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.loader .loader-spinner .loader-path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  stroke: $teal-500;
  transform-origin: center;
  animation: loader-dash 1.4s ease-in-out infinite
}

@keyframes loader-dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.loader .loader-title {
  margin: 8px auto 0 auto;
  text-align: center;
  color: $text-color-3;
  font-size: 1rem;
  font-weight: 400;
}

#app.dark {

  .loader .loader-spinner .loader-path {
    stroke: $white;
  }

  .loader .loader-title {
    color: rgba(255, 255, 255, 0.54);
  }

}

</style>
