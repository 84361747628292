<template>

  <base-edit toptitle="Abrechnungsinformationen bearbeiten"
             name="settings-payment-customer-edit"
             :title="paymentCustomer.name"
             :width="400"
             v-bind="$attrs"
             v-on="$listeners">
    <SettingsPaymentCustomerForm :paymentCustomer="paymentCustomer" type="edit"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import SettingsPaymentCustomerForm from '@/components/settings/payment/customer/SettingsPaymentCustomerForm'

export default {
  name: 'SettingsPaymentCustomerEdit',
  components: { SettingsPaymentCustomerForm, BaseEdit },
  props: {
    paymentCustomer: Object
  }
}
</script>
