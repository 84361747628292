import Vue from 'vue'
import Vuex from 'vuex'
import VuexReset from '@ianwalter/vuex-reset'

import app from './modules/app'
import auth from './modules/auth'
import channels from './modules/channels'
import projects from './modules/projects'
import workspaces from './modules/workspaces'
import workspaceUsers from './modules/workspaceUsers'
import projectWorkspaceUsers from './modules/projectWorkspaceUsers'
import users from './modules/users'
import postings from './modules/postings'
import revisions from './modules/revisions'
import postingMedia from './modules/postingMedia'
import projectCovers from './modules/projectCovers'
import dashboard from './modules/dashboard'
import news from './modules/news'
import paymentInvoices from './modules/payment/paymentInvoices'
import paymentSubscriptions from './modules/payment/paymentSubscriptions'
import paymentMethods from './modules/payment/paymentMethods'
import paymentPrices from './modules/payment/paymentPrices'
import paymentCustomers from './modules/payment/paymentCustomers'
import paymentSetupIntents from './modules/payment/paymentSetupIntents'
import stats from './modules/stats'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [VuexReset()],
  mutations: {
    async reset () {}
  },
  modules: {
    app,
    auth,
    channels,
    projects,
    projectWorkspaceUsers,
    projectCovers,
    workspaces,
    workspaceUsers,
    users,
    postings,
    postingMedia,
    revisions,
    dashboard,
    news,
    paymentInvoices,
    paymentSubscriptions,
    paymentMethods,
    paymentPrices,
    paymentCustomers,
    paymentSetupIntents,
    stats
  }
})
