import createCrudModule from '@/utils/createCrudModule'
import _ from 'lodash'
import i18n from '@/utils/i18n'

export default createCrudModule({
  resource: 'users',
  urlRoot: '/users',
  state: {
    filter: {
      search: '',
      roles: [],
      sortBy: { label: i18n.t('sortBy.name'), value: 'name', icon: 'user-check' },
      sortOrder: { label: i18n.t('sortOrder.asc'), value: 'asc', icon: 'sort-amount-up' },
      archived: false
    }
  },
  getters: {
    filter: state => state.filter,
    filtered: state => {
      return _.orderBy(Object.values(state.entities), state.filter.sortBy.value, state.filter.sortOrder.value)
        .filter(user => user.name.toLowerCase().indexOf(state.filter.search.toLowerCase()) > -1)
        .filter(user => !state.filter.roles.length || (user.roles && user.roles
          .some(userRole => state.filter.roles.some(filterRole => userRole === filterRole.value))))
        .filter(user => state.filter.archived !== user.active)
    },
    active: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(user => user.active)
    },
    admins: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.roles.some(role => ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].indexOf(role) > -1))
    },
    managers: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.roles.some(role => ['ROLE_MANAGER'].indexOf(role) > -1))
    },
    editors: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(editor => editor.roles.some(role => ['ROLE_EDITOR'].indexOf(role) > -1))
    },
    customers: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.roles.some(role => ['ROLE_CUSTOMER'].indexOf(role) > -1))
    },
    guests: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.roles.some(role => ['ROLE_GUEST'].indexOf(role) > -1))
    }
  }
})
