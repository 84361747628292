<template>
  <div class="list-card">
    <div class="list-card-top" v-if="title">
      <font-awesome-icon v-if="icon" :icon="icon"/>
      <div>
        <div class="title">
          {{ title }}
          <span>{{ count }}</span>
        </div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
      <div class="action">
        <slot name="action"/>
      </div>
    </div>

    <div class="list-card-items" v-if="loading">
      <base-list-card-item-loader />
    </div>

    <div v-if="$slots['default']" class="list-card-items">
      <slot v-if="!loading"></slot>
    </div>

  </div>
</template>

<script>
import BaseListCardItemLoader from '@/components/base/list/BaseListCardItemLoader'

export default {
  name: 'BaseListCard',
  components: { BaseListCardItemLoader },
  props: {
    icon: String,
    title: String,
    subtitle: String,
    count: Number,
    loading: Boolean
  }
}
</script>

<style lang="scss">

.list-card {

}

.list-card .list-card-top {
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 50px;
}

.list-card .list-card-top svg {
  margin-right: 5px;
  font-size: 1rem;
}

.list-card .list-card-top .title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $text-color-1
}

.list-card .list-card-top .title span {
  font-weight: 500;
  color: $text-color-2;
}

.list-card .list-card-top .subtitle {
  color: $text-color-2;
  font-size: 0.875rem;
}

.list-card .list-card-top .action {
  margin-left: auto;
}

.list-card .list-card-items {
  border-radius: 8px;
  box-shadow: $box-shadow-1;
  margin-bottom: 15px;
}

#app.dark {
  .list-card .list-card-top .title {
    color: $gray-300;
  }

  .list-card .list-card-top .title span {
    color: $gray-500;
  }
}
</style>
