<template>

  <section class="not-found-wrapper">
    <div class="not-found">
      <font-awesome-icon icon="search"/>
      <div>{{ message }}</div>
      <div>Seite nicht gefunden</div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'NotFound',
  props: {
    icon: String,
    message: String
  }
}
</script>

<style lang="scss">

.not-found-wrapper {
  position: sticky;
  padding: 0 20px;
}

.not-found-wrapper::before {
  content: "";
  background-color: $teal-500;
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 60px 20px;
  color: $text-color-1;
  font-weight: 700;
  font-size: 1.25rem;
  background-color: $white;
  max-width: 1200px;
  border-radius: 5px;
  box-shadow: $box-shadow-1;
}

.not-found svg {
  display: block;
  font-size: 1.25rem;
  color: $text-color-1;
  margin-right: 10px;
}

#app.dark {

  .not-found {
    color: $gray-700;
  }

  .not-found svg {
    color: $gray-700;
  }
}

</style>
