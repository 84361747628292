/** KONNEKTOR ********/
/** Copyright © 2022 */
/** Nils Reymers *****/

import Vue from 'vue'
import router from './router/index'
import store from './store/index'
import App from './components/App.vue'

import ButtonText from './components/utils/button/ButtonText.vue'
import ButtonContained from './components/utils/button/ButtonContained.vue'
import NotFound from './components/utils/NotFound.vue'
import LoaderWrapper from './components/utils/LoaderWrapper.vue'
import i18n from './utils/i18n'

import ProjectsCreate from '@/components/projects/ProjectsCreate'
import ProjectsEdit from '@/components/projects/ProjectsEdit'
import UsersCreate from '@/components/users/UsersCreate'
import UsersEdit from '@/components/users/UsersEdit'
import ProjectPostingItemDatetimeModal from '@/components/project/posting/item/ProjectPostingItemDatetimeModal'
import ProjectPostingItemDuplicateModal from '@/components/project/posting/item/ProjectPostingItemDuplicateModal'
import ProjectPostingItemArchiveModal from '@/components/project/posting/item/ProjectPostingItemArchiveModal'
import ProjectPostingItemRestoreModal from '@/components/project/posting/item/ProjectPostingItemRestoreModal'
import ProjectPostingItemMediaModal from '@/components/project/posting/item/ProjectPostingItemMediaModal'
import ProjectPostingItemPublishModal from '@/components/project/posting/item/ProjectPostingItemPublishModal'
import ProjectPostingRevisionRestoreModal from '@/components/project/posting/revision/ProjectPostingRevisionRestoreModal'
import SettingsAccountEdit from '@/components/settings/account/SettingsAccountEdit'
import SettingsWorkspaceEdit from '@/components/settings/workspace/SettingsWorkspaceEdit'
import SettingsWorkspaceSlackNotificationEdit from '@/components/settings/workspace/SettingsWorkspaceSlackNotificationEdit'
import SettingsWorkspacePlatformCreate from '@/components/settings/workspace/SettingsWorkspacePlatformCreate'
import SettingsWorkspacePlatformEdit from '@/components/settings/workspace/SettingsWorkspacePlatformEdit'
import SettingsPaymentSubscriptionCreate from '@/components/settings/payment/subscription/SettingsPaymentSubscriptionCreate'
import SettingsPaymentSubscriptionUpdate from '@/components/settings/payment/subscription/SettingsPaymentSubscriptionUpdate'
import SettingsPaymentSubscriptionEdit from '@/components/settings/payment/subscription/SettingsPaymentSubscriptionEdit'
import SettingsPaymentMethodCreate from '@/components/settings/payment/methods/SettingsPaymentMethodCreate'
import SettingsPaymentMethodEdit from '@/components/settings/payment/methods/SettingsPaymentMethodEdit'
import SettingsPaymentCustomerEdit from '@/components/settings/payment/customer/SettingsPaymentCustomerEdit'
import DashboardCreatePostingModal from '@/components/dashboard/grid/DashboardCreatePostingModal'

import './utils/axios'
import './utils/fontawesome'
import './utils/moment'
import './utils/multiselect'
import './utils/spinner'
import './utils/vcalendar'
import './utils/vue-final-modal'
import './utils/toasted'
import './utils/scrollto'
import './utils/sentry'
import './utils/vueTour'

import 'reset-css'
import './css/app.scss'

Vue.component('ButtonText', ButtonText)
Vue.component('ButtonContained', ButtonContained)
Vue.component('NotFound', NotFound)
Vue.component('LoaderWrapper', LoaderWrapper)

// modals
Vue.component('ProjectsCreate', ProjectsCreate)
Vue.component('ProjectsEdit', ProjectsEdit)
Vue.component('UsersCreate', UsersCreate)
Vue.component('UsersEdit', UsersEdit)
Vue.component('ProjectPostingItemMediaModal', ProjectPostingItemMediaModal)
Vue.component('ProjectPostingItemRestoreModal', ProjectPostingItemRestoreModal)
Vue.component('ProjectPostingItemArchiveModal', ProjectPostingItemArchiveModal)
Vue.component('ProjectPostingItemDuplicateModal', ProjectPostingItemDuplicateModal)
Vue.component('ProjectPostingItemPublishModal', ProjectPostingItemPublishModal)
Vue.component('ProjectPostingItemDatetimeModal', ProjectPostingItemDatetimeModal)
Vue.component('ProjectPostingRevisionRestoreModal', ProjectPostingRevisionRestoreModal)
Vue.component('SettingsAccountEdit', SettingsAccountEdit)
Vue.component('SettingsWorkspaceEdit', SettingsWorkspaceEdit)
Vue.component('SettingsWorkspacePlatformCreate', SettingsWorkspacePlatformCreate)
Vue.component('SettingsWorkspacePlatformEdit', SettingsWorkspacePlatformEdit)
Vue.component('SettingsWorkspaceSlackNotificationEdit', SettingsWorkspaceSlackNotificationEdit)
Vue.component('SettingsPaymentCustomerEdit', SettingsPaymentCustomerEdit)
Vue.component('SettingsPaymentSubscriptionCreate', SettingsPaymentSubscriptionCreate)
Vue.component('SettingsPaymentSubscriptionUpdate', SettingsPaymentSubscriptionUpdate)
Vue.component('SettingsPaymentSubscriptionEdit', SettingsPaymentSubscriptionEdit)
Vue.component('SettingsPaymentMethodCreate', SettingsPaymentMethodCreate)
Vue.component('SettingsPaymentMethodEdit', SettingsPaymentMethodEdit)
Vue.component('DashboardCreatePostingModal', DashboardCreatePostingModal)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
