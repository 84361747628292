<template>

  <base-edit name="projects-edit"
             :title="projectName"
             :toptitle="$t('projects.form.edit')"
             v-bind="$attrs"
             v-on="$listeners">
    <projects-form :project="project" type="edit"/>
  </base-edit>

</template>

<script>
import BaseEdit from '@/components/base/form/BaseEdit'
import ProjectsForm from './ProjectsForm.vue'

export default {
  name: 'ProjectsEdit',
  components: { BaseEdit, ProjectsForm },
  props: {
    project: {
      type: Object,
      default: () => ({
        project: {
          name: null
        }
      })
    }
  },
  computed: {
    projectName () {
      return this.project.name ? this.project.name.replace(/[-]/gm, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : 'Projekt'
    }
  }
}
</script>
