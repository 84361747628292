<template>
  <base-list title="Ereignisse" subtitle="Bis Ende der Woche" :count="events.length" icon="calendar-alt">
    <base-list-card>
      <VirtualList style="height: 600px; overflow-y: auto;"
                   :data-key="'title'"
                   :data-sources="filteredEvents"
                   :data-component="dashboardEventItem"
                   :page-mode="false"
                   :estimate-size="50"/>

      <template #action>

        <vue-multiselect v-model="selectedCategories"
                     :options="categories"
                     :showLabels="false"
                     :hide-selected="true"
                     :multiple="true"
                     :custom-label="filterLabel"
                     placeholder="Kategorien">
          <template #placeholder>
            <font-awesome-icon icon="tags"/>
            <span>Kategorien</span>
          </template>
          <template slot="singleLabel" slot-scope="props">
            <font-awesome-icon :icon="categoryIcon(props.option)" class="option__image"/>
            <span>{{ filterLabel(props.option) }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <font-awesome-icon :icon="categoryIcon(props.option)" class="option__image"/>
            {{ filterLabel(props.option) }}
          </template>
        </vue-multiselect>

      </template>
    </base-list-card>
  </base-list>
</template>

<script>
import BaseList from '@/components/base/list/BaseList'
import actionDays from '@/lib/actionDaysCat.json'
import DashboardEventItem from '@/components/dashboard/grid/events/DashboardEventItem'
import VirtualList from 'vue-virtual-scroll-list'
import moment from 'moment'
import { startCase } from 'lodash'
import BaseListCard from '@/components/base/list/BaseListCard'

export default {
  name: 'DashboardEvents',
  props: {
    loading: Boolean
  },
  data () {
    return {
      actionDays,
      dashboardEventItem: DashboardEventItem,
      selectedCategories: []
    }
  },
  components: { BaseListCard, BaseList, VirtualList },
  computed: {
    categories () {
      return ['gesundheit', 'kultur', 'spaß', 'essen', 'natur', 'tiere', 'sport', 'beruf', 'technik']
    },
    events () {
      const days = []
      const fromDate = moment()
      const toDate = moment().add(1, 'weeks')
      this.actionDays.forEach(month => month.days.forEach(day => {
        const currentDay = moment({ months: month.month - 1, date: day.day })
        if (currentDay.isBetween(fromDate, toDate, undefined, '[]')) {
          days.push({
            icon: this.categoryIcon(day.category),
            title: day.name,
            toptitle: currentDay.calendar(null, {
              sameDay: '[Heute], D. MMM',
              nextDay: '[Morgen], D. MMM',
              nextWeek: 'dddd, D. MMM',
              lastDay: '[Gestern]',
              lastWeek: '[Diesen] dddd',
              sameElse: 'dddd, D.MMM'
            }),
            category: day.category,
            datetime: currentDay.utc().add(12, 'hour')
          })
        }
      }))
      return days
    },
    filteredEvents () {
      return this.events.filter(event => !this.selectedCategories.length || this.selectedCategories.some(category => category === event.category))
    }
  },
  methods: {
    categoryIcon (icon) {
      switch (icon) {
        case 'gesundheit':
          return 'heartbeat'
        case 'kultur':
          return 'landmark'
        case 'spaß':
          return 'grin-tears'
        case 'essen':
          return 'utensils'
        case 'natur':
          return 'leaf'
        case 'tiere':
          return 'dog'
        case 'sport':
          return 'basketball-ball'
        case 'beruf':
          return 'briefcase'
        case 'technik':
          return 'laptop'
      }
    },
    filterLabel (label) {
      return `${startCase(label)}`
    }
  }
}
</script>
