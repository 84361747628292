import createCrudModule from '@/utils/createCrudModule'
import _ from 'lodash'
import i18n from '@/utils/i18n'

export default createCrudModule({
  resource: 'workspaceUsers',
  urlRoot: '/workspace_users',
  state: {
    filter: {
      search: '',
      roles: [],
      sortBy: { label: i18n.t('sortBy.name'), value: 'name', icon: 'user-check' },
      sortOrder: { label: i18n.t('sortOrder.asc'), value: 'asc', icon: 'sort-amount-up' },
      status: { icon: 'check-square', label: i18n.t('users.top.active'), value: false }
    }
  },
  getters: {
    filter: state => state.filter,
    filtered: state => {
      return _.orderBy(Object.values(state.entities), state.filter.sortBy.value, state.filter.sortOrder.value)
        .filter(workspaceUser => workspaceUser.user.name.toLowerCase().indexOf(state.filter.search.toLowerCase()) > -1)
        .filter(workspaceUser => !state.filter.roles.length || state.filter.roles.some(role => workspaceUser.role === role.value))
        .filter(workspaceUser => state.filter.status.value !== workspaceUser.active)
    },
    active: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(workspaceUser => workspaceUser.active)
    },
    admins: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.role === 5)
    },
    managers: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.role === 4)
    },
    editors: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(editor => editor.role === 3)
    },
    customers: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.role === 2)
    },
    guests: (state, getters) => {
      return _.orderBy(getters.filtered, 'name', 'asc')
        .filter(manager => manager.role === 1)
    },
    tourCompleted: (state, getters, rootState, rootGetters) => rootGetters['auth/currentUser'].tours.users
  }
})
