<template>

  <div class="backend">
    <LoadingScreen v-if="loading" />
    <TheHeader v-if="!loading" />
    <router-view v-if="!loading" />
  </div>

</template>

<script>
import TheHeader from './TheHeader.vue'
import LoadingScreen from '@/components/utils/LoadingScreen'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BackendView',
  components: {
    TheHeader,
    LoadingScreen
  },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        await this.getUser({ id: this.authUser.id })
        await this.getWorkspace({ id: this.currentUser.workspace.id })
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    ...mapActions({
      getUser: 'users/fetchSingle',
      getWorkspace: 'workspaces/fetchSingle'
    })
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/authUser',
      currentUser: 'auth/currentUser'
    })
  }
}
</script>
