<template>

  <div class="init-wrapper">
    <div class="init">
      <div class="logo">
        <img :src="logoSrc"/>
      </div>
      <!--      <div class="logo">K</div>-->
      <!--                  <div class="message">{{ message }}</div>-->
      <!--                  <div class="slider-wrapper">-->
      <!--                      <div class="slider">-->
      <!--                          <div class="toggle"></div>-->
      <!--                      </div>-->
      <!--                  </div>-->

      <clip-loader :color="loaderColor"
                   size="32px">
      </clip-loader>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoadingScreen',
  computed: {
    loaderColor () {
      return this.darkmodeStatus ? '#FFFFFF' : 'rgba(0,0,0,0.38)'
    },
    logoSrc () {
      return this.darkmodeStatus ? '/static/konnektor_bildmarke_white.svg' : '/static/konnektor_bildmarke_green.svg'
    },
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>

<style lang="scss">

.init-wrapper {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $gray-50;
  z-index: 9999;
}

.init-wrapper .init {
  position: absolute;
  margin-left: -150px;
  margin-top: -100px;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.init .logo {
  text-align: center;
  font-family: 'Lovelo', sans-serif;
  color: $teal-500;
  font-size: 3rem;
  height: 40px;
  margin-bottom: 30px;
}

.init .logo img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.init .message {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.init .slider-wrapper {
  display: flex;
  align-items: center;
}

.init .slider-wrapper .slider {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 50px;
  height: 20px;
  width: 72px;
  margin: 0 auto;
  animation: slider 3s ease infinite;
}

@keyframes slider {
  0% {
    background-color: $gray-400;
  }

  10% {
    background-color: $gray-400;
  }

  20% {
    background-color: $red-100;
  }

  40% {
    background-color: $red-100;
  }

  50% {
    background-color: $teal-100;
  }

  70% {
    background-color: $teal-100;
  }

  80% {
    background-color: $gray-400;
  }

  100% {
    background-color: $gray-400;
  }
}

.init .slider-wrapper .slider .toggle {
  position: absolute;
  left: 0;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  background-color: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  animation: toggle 3s ease infinite;
}

@keyframes toggle {
  0% {
    left: 0;
    background-color: $white;
  }

  10% {
    left: 0;
    background-color: $white;
  }

  20% {
    left: 22px;
    background-color: $red-300;
  }

  40% {
    left: 22px;
    background-color: $red-300;
  }

  50% {
    left: 44px;
    background-color: $teal-500;
  }

  70% {
    left: 44px;
    background-color: $teal-500;
  }

  80% {
    left: 0;
    background-color: $white;
  }

  100% {
    left: 0;
    background-color: $white;
  }
}

#app.dark {
  .init-wrapper {
    background-color: $dark-1;
  }

  .init .logo {
    color: $white;
  }
}

</style>
