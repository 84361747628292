<template>
  <base-list-card-item :toptitle="source.toptitle"
                  :icon="source.icon"
                  :title="source.title">
    <template #hover>
      <ButtonText label="Posting" icon="plus"
                  @click="$vfm.show({component: 'DashboardCreatePostingModal', bind: {subtitle: source.title, datetime: source.datetime, comment: comment}})"/>
    </template>
  </base-list-card-item>
</template>

<script>
import BaseListCardItem from '@/components/base/list/BaseListCardItem'

export default {
  name: 'DashboardEventItem',
  components: { BaseListCardItem },
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    comment () {
      return `Heute ist ${this.source.title}`
    }
  }
}
</script>
