<template>

  <base-button :disabled="disabled"
               :icon="icon"
               :label="label"
               :loading="loading"
               :remove="remove"
               :save="save"
               :type="type"
               design="text"
               @click="$emit('click')">
  </base-button>

</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'

export default {
  name: 'ButtonText',
  components: { BaseButton },
  props: {
    type: String,
    icon: String,
    disabled: Boolean,
    label: String,
    loading: Boolean,
    save: Boolean,
    remove: Boolean
  }
}
</script>

<style lang="scss">

.button.button-text {
  background: none;
}

.button.button-text:hover {
  background-color: $gray-100;
}

.button.button-text:active {
  background-color: $gray-200;
}

.button[disabled].button-text {
  background: none;
}

.button[disabled]:hover {
  box-shadow: none;
}

#app.dark {

  .button.button-text:hover {
    background-color: $dark-4;
  }

  .button.button-text:active {
    background-color: $dark-4;
  }

  .button[disabled].button-text {
    background: none;
  }

}

</style>
