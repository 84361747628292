<template>
  <vue-final-modal
    name="dashboard-create-posting-modal"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': '400px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs">
    <section class="modal">
      <div class="top">
        <div class="title">Posting erstellen</div>
        <div class="subtitle">für {{ subtitle }} in</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>

      <vue-multiselect v-model="project"
                   :multiple="false"
                   :options="projects"
                   :showLabels="false"
                   placeholder="Projekt"
                   track-by="id"
                   :custom-label="label">
        <template #placeholder>
          <font-awesome-icon icon="stream"/>
          <span>Projekt</span>
        </template>
      </vue-multiselect>

      <div class="action">
        <ButtonText :loading="creating"
                    icon="plus"
                    label="Erstellen"
                    type="submit"
                    :disabled="!project"
                    @click="create"/>
      </div>
    </section>

  </vue-final-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { startCase } from 'lodash'

export default {
  name: 'DashboardCreatePostingModal',
  data () {
    return {
      project: null,
      posting: {
        project: null,
        datetime: null,
        comment: null,
        platform: 'facebook',
        isDraft: false
      },
      creating: false
    }
  },
  props: {
    datetime: Object,
    comment: String,
    subtitle: String
  },
  created () {
    this.project = null
    this.posting.datetime = this.datetime
    this.posting.comment = this.comment
  },
  methods: {
    async create () {
      try {
        this.creating = true
        this.posting.project = '/projects/' + this.project.name
        await this.createPosting({ data: this.posting })
        this.$vfm.hide('dashboard-create-posting-modal')
        this.$toasted.success('Posting erstellt', { icon: 'fas fa-plus' })
        this.creating = false
        this.$router.push({ name: 'projectPosting', params: { name: this.project.name } })
      } catch (error) {
        this.creating = false
        this.$toasted.error(error.response.data.detail)
      }
    },
    label (project) {
      return startCase(project.name)
    },
    ...mapActions({
      createPosting: 'postings/create'
    })
  },
  computed: {
    ...mapGetters({
      projects: 'projects/active'
    })
  }
}
</script>
