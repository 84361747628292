<template>

  <div class="bar-item">
    <font-awesome-icon v-if="icon" :icon="icon"/>
    <div>
      <div class="bar-toptitle">{{ toptitle }}</div>
      <div class="bar-title">{{ title }}</div>
      <div class="bar-subtitle">{{ subtitle }}</div>
      <slot/>
    </div>
    <slot name="action"></slot>
  </div>

</template>

<script>
export default {
  name: 'BaseBarItem',
  props: {
    icon: String,
    toptitle: String,
    title: String,
    subtitle: String,
    cols: Array
  }
}
</script>

<style lang="scss">

.bar-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 10px 0;
  padding: 0 20px;
  border-right: 1px solid $text-color-4;
  width: 150px;
}

.bar-item:last-child {
  border-right: none;
}

.bar-item svg {
  font-size: 1.5rem;
  margin-right: 10px;
  color: $text-color-1;
}

.bar-item .bar-toptitle,
.bar-item .bar-subtitle {
  color: $text-color-2;
  font-size: 0.875rem;
}

.bar-item .bar-title {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  color: $text-color-1;
}

.bar-item button,
.bar-item .multiselect {
  margin-left: auto;
}

@media only screen and (max-width: 768px) {

  .bar-item {
    width: 100%;
    margin: 0 20px;
    padding: 10px 0;
    border-right: none;
    border-bottom: 1px solid $text-color-4;
  }

  .bar-item:last-child {
    border-bottom: none;
  }

}

#app.dark {

  .bar-item .bar-toptitle,
  .bar-item .bar-subtitle {
    color: $gray-500;
  }

  .bar-item {
    border-right-color: $gray-800;
  }

  .bar-item svg {
    color: $white;
  }

  .bar-item .bar-title {
    color: $white;
  }

  @media only screen and (max-width: 768px) {

    .bar-item {
      border-bottom-color: $gray-800;
    }

  }
}

</style>
