import { render, staticRenderFns } from "./ButtonContained.vue?vue&type=template&id=4b352b47"
import script from "./ButtonContained.vue?vue&type=script&lang=js"
export * from "./ButtonContained.vue?vue&type=script&lang=js"
import style0 from "./ButtonContained.vue?vue&type=style&index=0&id=4b352b47&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports