<template>

  <vue-final-modal
    name="project-posting-item-duplicate-modal"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': '400px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <section class="modal">
      <div class="top">
        <div class="title">{{ $t('postings.duplicate.title') }}</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>
      {{ $t('postings.duplicate.text') }}

      <div class="action">
        <ButtonText :label="$t('button.duplicate')"
                    :loading="duplicating"
                    icon="clone"
                    type="button"
                    @click="duplicate"/>
      </div>
    </section>

  </vue-final-modal>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProjectPostingItemDuplicateModal',
  data () {
    return {
      duplicating: false
    }
  },
  props: {
    posting: Object
  },
  methods: {
    async duplicate () {
      try {
        this.duplicating = true
        await this.createPosting({ customUrl: '/postings/' + this.posting.id + '/duplicate' })
        this.$vfm.hide('project-posting-item-duplicate-modal')
        this.$toasted.success('Posting dupliziert', { icon: 'fas fa-clone' })
        this.duplicating = false
      } catch (error) {
        this.duplicating = false
      }
    },
    ...mapActions({
      createPosting: 'postings/create'
    })
  }
}
</script>
