<template>

  <section :class="{'wrapper-grid': true, 'background': background, 'large-background': largeBackground}">

    <div class="top" v-if="title">
      <font-awesome-icon :icon="icon"/>
      <div>
        <div class="title">{{ title }} <span>{{ count }}</span></div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
    </div>

    <div class="grid">
      <slot name="grid"/>
    </div>

    <slot></slot>

  </section>

</template>

<script>
export default {
  name: 'BaseGrid',
  props: {
    background: {
      type: Boolean,
      default: false
    },
    largeBackground: {
      type: Boolean,
      default: false
    },
    title: String,
    subtitle: String,
    count: Number,
    icon: String
  }
}
</script>

<style lang="scss">

.wrapper-grid {
  position: sticky;
  margin-bottom: 20px;
}

.wrapper-grid.background::before {
  content: "";
  background-color: $teal-500;
  width: 100%;
  height: 65px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.wrapper-grid.large-background::before {
  content: "";
  background-color: $teal-500;
  width: 100%;
  height: 145px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.wrapper-grid .top {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 10px 20px 10px 20px;
  max-width: 1240px;
}

.wrapper-grid .top svg {
  margin-right: 10px;
}

.wrapper-grid .top .title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2px;
}

.wrapper-grid .top .title span {
  font-weight: 500;
  color: $text-color-3
}

.wrapper-grid .top .subtitle {
  color: $text-color-2;
  font-size: 0.875rem;
}

.wrapper-grid .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1240px;
}

#app.dark {

  .wrapper-grid::before {
    background-color: $dark-1;
  }

    .wrapper-grid .top .title span {
        color: $gray-500;
    }

}

</style>
