<template>
  <base-form>
    <template #form>
      <BaseInput v-model="form.slackChannel"
                 placeholder="Kanal"
                 :icon="['fas', 'hashtag']"
                 type="text"/>

    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :remove="true"
                  :loading="removing"
                  icon="trash"
                  :label="$t('button.remove')"
                  @click="remove"/>
      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  :label="$t('button.save')"
                  type="submit"
                  @click="update"/>
    </template>

  </base-form>
</template>

<script>
import BaseForm from '@/components/base/form/BaseForm'
import BaseInput from '@/components/base/input/BaseInput'
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'

export default {
  name: 'SettingsWorkspaceSlackNotificationForm',
  components: {
    BaseForm,
    BaseInput
  },
  props: {
    type: String,
    workspace: Object
  },
  data () {
    return {
      form: {},
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.workspace)
  },
  methods: {
    async update () {
      this.updating = true

      await this.updateWorkspace({
        id: this.form.id,
        data: this.form
      })
      this.$vfm.hide('settings-workspace-slack-notification-edit')
      this.$toasted.success('Slack Benachrichtigungen angepasst', { icon: 'fab fa-slack' })
      this.updating = false
    },
    async remove () {
      this.removing = true

      this.form.slackToken = null
      this.form.slackChannel = null

      await this.updateWorkspace({
        id: this.form.id,
        data: this.form
      })
      this.$vfm.hide('settings-workspace-slack-notification-edit')
      this.$toasted.success('Slack Benachrichtigungen entfernt', { icon: 'fab fa-slack' })
      this.removing = false
    },
    ...mapActions({
      getWorkspace: 'workspaces/fetchSingle',
      updateWorkspace: 'workspaces/replace'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.name)
    }
  }
}
</script>
