<template>

  <base-grid>
    <template #grid>
      <DashboardEvents :loading="loading"/>
      <DashboardNews :loading="loading"/>
      <DashboardTrends :loading="loading"/>
      <!--      <DashboardProjects :loading="loading"/>-->
      <!--      <DashboardActivities :loading="loading"/>-->
    </template>
  </base-grid>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseGrid from '@/components/base/grid/BaseGrid'
import DashboardEvents from '@/components/dashboard/grid/events/DashboardEvents'
import DashboardTrends from '@/components/dashboard/grid/trends/DashboardTrends'
import DashboardNews from '@/components/dashboard/grid/news/DashboardNews'

export default {
  name: 'DashboardGrid',
  components: { DashboardNews, DashboardTrends, DashboardEvents, BaseGrid },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.projects.length || this.getProjects(),
        this.postings.length || this.getPostings()
      ])
      this.loading = false
    },
    ...mapActions({
      getProjects: 'projects/fetchList',
      getPostings: 'postings/fetchList'
    })
  },
  computed: {
    ...mapGetters({
      projects: 'projects/personal',
      postings: 'postings/list'
    })
  }
}
</script>
