<template>
  <base-bar-item :title="time"
                 :toptitle="toptitle"
                 icon="clock"/>
</template>

<script>
import moment from 'moment'
import BaseBarItem from '@/components/base/bar/BaseBarItem'

export default {
  name: 'DashboardTopDatetime',
  components: { BaseBarItem },
  computed: {
    toptitle () {
      return `${moment().format('dddd')}, ${moment().format('D. MMM YYYY')}`
    },
    date () {
      return moment().format('D. MMM YYYY')
    },
    time () {
      return `${moment().format('HH:mm')} Uhr`
    },
    weekday () {
      return moment().format('dddd')
    }
  }
}
</script>
