<template>

  <base-form>
    <template #form>
      <div class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="table"/>
            Allgemein
          </div>
          <div class="group-subtitle">Lege einen Namen für dein Projekt fest.</div>
        </div>

        <div class="group-fields">
          <BaseInput v-model="projectName"
                     :placeholder="$t('projects.form.name')"
                     icon="table"
                     type="text"
                     data-v-step="projects-form-name"/>
        </div>
      </div>

      <div class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="shield-alt"/>
            Berechtigungen
          </div>
          <div class="group-subtitle">
            Lege Benutzer für dieses Projekt fest.
            <ul>
              <li><span>Administrator</span> Workspace verwalten</li>
              <li><span>Manager</span> Projekte erstellen</li>
              <li><span>Redakteure</span> Beiträge erstellen</li>
              <li><span>Kunden</span> Beiträge freigeben</li>
              <li><span>Gäste</span> Lesezugriff</li>
            </ul>
          </div>
        </div>

        <div class="group-fields">
          <div class="multiselect-wrapper" data-v-step="projects-form-users">
            <label>{{ $t('projects.form.users') }}</label>
            <vue-multiselect
              v-model="form.workspaceUsers"
              :custom-label="userLabel"
              :hide-selected="false"
              :multiple="true"
              :option-height="40"
              :options="userOptions"
              :placeholder="$t('projects.form.users')"
              :showLabels="false"
              :close-on-select="false"
              group-values="users"
              group-label="label"
              track-by="id"
              :loading="loading">
              <template #placeholder>
                <font-awesome-icon icon="users"/>
                <span>{{ $t('projects.form.users') }}</span>
              </template>
            </vue-multiselect>
          </div>
        </div>
      </div>

      <div class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="image"/>
            Aussehen
          </div>
          <div class="group-subtitle">Lade ein Bild für dein Projekt hoch, um es schneller wieder zu finden.</div>
        </div>
        <div class="group-fields">
          <vue-dropzone id="dropzone"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        @vdropzone-mounted="dropzoneMounted"
                        @vdropzone-success="success"
                        data-v-step="projects-form-picture">
            <font-awesome-icon icon="cloud-upload-alt"/>
            {{ $t('projects.form.coverMessage') }}
          </vue-dropzone>
        </div>
      </div>

      <div class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="calendar-day"/>
            Datum
          </div>
          <div class="group-subtitle">Lege ein Veröffentlichungsdatum für dieses Projekt fest.</div>
        </div>
        <div class="group-fields">
          <div class="input-wrapper">
            <v-date-picker
              v-model="form.releaseDate"
              :is-dark="darkmodeStatus"
              :popover="{ visibility: 'click' }"
              color="gray"
              :locale="$i18n.locale"
              title-position="left">
              <template v-slot="{ inputValue, inputEvents }">
                <font-awesome-icon icon="calendar-day"/>
                <input id="input-release"
                       v-on="inputEvents"
                       :placeholder="$t('projects.form.date')"
                       :required="false"
                       :value="inputValue"
                       class="bg-white border px-2 py-1 rounded"
                       type="text"
                       data-v-step="projects-form-release">
                <label for="input-release">
                  {{ $t('projects.form.date') }}
                </label>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>

      <div v-if="hasSuperAdminRole" class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="layer-group"/>
            Kanäle
          </div>
          <div class="group-subtitle">Verbinde deine Kanäle in den Workspace Einstellungen und weise sie hier einem
            Projekt zu, um deine Beiträge zu planen oder zu
            veröffentlichen.
          </div>
        </div>
        <div class="group-fields">
          <div class="multiselect-wrapper">
            <label>Kanäle</label>
            <vue-multiselect
              v-model="form.channels"
              :hide-selected="false"
              :multiple="true"
              :option-height="40"
              :options="channelOptions"
              :placeholder="$t('projects.form.channels')"
              :showLabels="false"
              :close-on-select="false"
              track-by="id"
              label="name"
              :loading="loading">
              <template #placeholder>
                <font-awesome-icon icon="layer-group"/>
                <span>{{ $t('projects.form.channels') }}</span>
              </template>
              <template #option="props">
                <font-awesome-icon :icon="['fab', props.option.type.toLowerCase()]"/>
                {{ props.option.name }}
                <span v-if="props.option.$isDisabled">(Projekt: {{ props.option.project.replace("/project/") }})</span>
              </template>
            </vue-multiselect>
          </div>
        </div>
      </div>

      <div class="group" :class="{'last': type !== 'edit'}">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="bullhorn"/>
            Marketing
          </div>
          <div class="group-subtitle">Lege ein Budget dieses Projekt fest.</div>
        </div>
        <div class="group-fields">
          <BaseInput v-model="budget"
                     :placeholder="$t('projects.form.budget')"
                     icon="euro-sign"
                     type="number"
                     data-v-step="projects-form-budget"/>
        </div>
      </div>

      <div class="group last" v-if="type === 'edit'">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="check-square"/>
            Status
          </div>
          <div class="group-subtitle">Wenn das Projekt abgeschlossen ist, kannst es hier archiviert werden.</div>
        </div>
        <div class="group-fields">

          <!--      <BaseInput v-model="form.facebook"-->
          <!--                 :icon="['fab', 'facebook']"-->
          <!--                 :placeholder="$t('projects.form.facebook')"-->
          <!--                 type="text"/>-->

          <BaseCheckbox v-if="type === 'edit'"
                        id="active"
                        v-model="form.isActive"
                        :placeholder="$t('projects.form.active')"/>
        </div>
      </div>

    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  :label="$t('button.save')"
                  type="submit"
                  @click="update"/>

      <ButtonText v-if="type === 'create'"
                  :disabled="checkForm"
                  :loading="creating"
                  icon="plus"
                  :label="$t('button.create')"
                  type="submit"
                  @click="create"
                  data-v-step="projects-form-create"/>
    </template>

  </base-form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseForm from '@/components/base/form/BaseForm'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { cloneDeep, startCase, orderBy } from 'lodash'
import BaseCheckbox from '@/components/base/checkbox/BaseCheckbox'
import BaseInput from '@/components/base/input/BaseInput'

export default {
  name: 'ProjectsForm',
  props: {
    project: {
      type: Object,
      default () {
        return {
          name: '',
          releaseDate: null,
          facebook: '',
          budget: null,
          isActive: true,
          cover: null
        }
      }
    },
    type: String
  },
  components: { BaseInput, BaseCheckbox, BaseForm, VueDropzone: vue2Dropzone },
  created () {
    this.form = cloneDeep(this.project)
    this.fetchData()
  },
  data () {
    return {
      loading: true,
      form: {},
      creating: false,
      updating: false,
      removing: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/projects/' + this.project.name + '/cover',
        acceptedFiles: 'image/jpeg, image/png',
        maxFiles: 1,
        autoProcessQueue: false,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': '',
          'X-Requested-With': ''
        },
        init () {
          this.on('addedfiles', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
          this.on('maxfilesexceeded', function (file) {
            this.removeAllFiles()
            this.addFile(file)
          })
        }
      },
      fbAppId: null,
      facebookScope: ['pages_show_list', 'pages_read_engagement', 'pages_manage_posts'],
      addingFacebook: false,
      instagramScope: ['pages_show_list', 'pages_read_engagement', 'instagram_basic', 'instagram_content_publish', 'instagram_manage_insights'],
      platform: null
    }
  },
  methods: {
    async fetchData () {
      try {
        this.workspaceUsers.length || await this.getWorkspaceUsers()
        this.channels.length || await this.getChannels()
        this.loading = false
      } catch (error) {
        console.log(error.response)
      }
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createProject({ data: this.form })
        this.form = response.data
        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
          this.$refs.myVueDropzone.setOption('url', process.env.VUE_APP_API_URL + '/projects/' + this.form.name + '/cover')
          this.$refs.myVueDropzone.processQueue()
        } else {
          this.$vfm.hide('projects-create')
          this.$toasted.success('Projekt erstellt', { icon: 'fas fa-plus' })
          this.creating = false
        }
        window.dataLayer.push({ event: 'project_created' })
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
          this.$refs.myVueDropzone.processQueue()
        } else {
          await this.updateProject({ id: this.project.name, data: this.form })
          this.$vfm.hide('projects-edit')
          this.$toasted.success('Projekt gespeichert', { icon: 'fas fa-save' })
          this.updating = false
          await this.getChannels()
          // await this.getProjects()
        }
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProject({ id: this.project.name })
        this.$vfm.hide('projects-edit')
        this.$toasted.success('Projekt gelöscht', { icon: 'fas fa-trash-alt' })
        this.removing = false
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.removing = false
      }
    },
    dropzoneMounted () {
      if (this.project.coverName) {
        this.$refs.myVueDropzone.manuallyAddFile({
          name: this.project.coverName,
          type: this.project.coverMimeType,
          size: this.project.coverSize
        }, process.env.VUE_APP_API_URL + this.project.coverUrl)
      }
    },
    async success (file, response) {
      // this.$toasted.success('Cover hochgeladen')
      // this.form.coverName = response.coverName
      // this.form.coverSize = response.coverSize
      // this.form.coverMimeType = response.coverMimeType
      // await this.updateProject({ id: this.form.id, data: this.form })
      this.$vfm.hideAll()
      this.$toasted.success('Projekt gespeichert', { icon: 'fas fa-save' })
      this.creating = false
      this.updating = false
      await this.getProject({ id: this.form.name })
    },
    userLabel ({ user }) {
      return startCase(user.name)
    },
    channelLabel ({ channel }) {
      return channel
    },
    ...mapActions({
      getProject: 'projects/fetchSingle',
      createProject: 'projects/create',
      updateProject: 'projects/replace',
      deleteProject: 'projects/destroy',
      getWorkspaceUsers: 'workspaceUsers/fetchList',
      getProjects: 'projects/fetchList',
      getChannels: 'channels/fetchList'
    })
  },
  computed: {
    projectName: {
      get () {
        return this.project.name ? this.project.name.replace(/[-]/gm, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''
      },
      set (value) {
        this.form.name = value
      }
    },
    userOptions () {
      return [
        { label: this.$t('projects.form.admins'), users: this.admins },
        { label: this.$t('projects.form.manager'), users: this.managers },
        { label: this.$t('projects.form.editor'), users: this.editors },
        { label: this.$t('projects.form.customer'), users: this.customers },
        { label: this.$t('projects.form.guests'), users: this.guests }
      ]
    },
    channelOptions () {
      const channels = this.channels
        .map(channel => {
          if (channel.project && channel.project !== '/projects/' + this.project.name) {
            return { ...channel, $isDisabled: true }
          } else {
            return channel
          }
        })
      return orderBy(channels, 'name', 'asc')
    },
    budget: {
      get () {
        return this.form.budget
      },
      set (value) {
        this.form.budget = value ? parseInt(value) : null
      }
    },
    checkForm () {
      return !(this.form.name)
    },
    ...mapGetters({
      workspaceUsers: 'workspaceUsers/active',
      admins: 'workspaceUsers/admins',
      managers: 'workspaceUsers/managers',
      customers: 'workspaceUsers/customers',
      editors: 'workspaceUsers/editors',
      guests: 'workspaceUsers/guests',
      currentUser: 'auth/currentUser',
      hasSuperAdminRole: 'auth/hasSuperAdminRole',
      darkmodeStatus: 'app/darkmodeStatus',
      channels: 'channels/list'
    })
  }
}
</script>
