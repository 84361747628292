<template>

  <base-form>
    <template #form>
      <BaseInput v-model="form.name"
                 placeholder="Name*"
                 icon="building"
                 type="text"/>
      <BaseInput v-model="form.email"
                 placeholder="E-Mail*"
                 icon="envelope"
                 type="email"/>
      <BaseInput v-model="form.line1"
                 placeholder="Adresse*"
                 icon="home"
                 type="text"/>
      <BaseInput v-model="form.city"
                 placeholder="Stadt*"
                 icon="city"
                 type="text"/>
      <BaseInput v-model="form.postalCode"
                 placeholder="Postleitzahl*"
                 icon="city"
                 type="text"
                 :maxlength="5"/>
      <vue-multiselect v-model="formCountry"
                   :options="formCountryOptions"
                   :showLabels="false"
                   :multiple="false"
                   track-by="value"
                   placeholder="Land">
        <template #placeholder>
          <font-awesome-icon icon="globe"/>
          <span>Land*</span>
        </template>
        <template #singleLabel="props">
          <font-awesome-icon icon="globe"/>
          <span>{{ props.option.label }}</span>
        </template>
        <template #option="props">
          <font-awesome-icon icon="globe"/>
          {{ props.option.label }}
        </template>
      </vue-multiselect>

      <BaseInput v-model="form.taxId"
                 placeholder="Steuer-ID"
                 icon="university"
                 type="text"/>
      <div class="subtitle">* Pflichtfelder</div>
    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  :label="$t('button.save')"
                  type="submit"
                  @click="update"/>
    </template>

  </base-form>

</template>

<script>
import { mapActions } from 'vuex'
import BaseForm from '@/components/base/form/BaseForm'
import { cloneDeep } from 'lodash'
import BaseInput from '@/components/base/input/BaseInput'

export default {
  name: 'SettingsPaymentCustomerForm',
  props: {
    paymentCustomer: {
      type: Object,
      default () {
        return {
          name: null,
          email: null,
          line1: null,
          city: null,
          postalCode: null,
          country: 'DE',
          taxId: null
        }
      }
    },
    type: String
  },
  components: { BaseInput, BaseForm },
  created () {
    this.form = cloneDeep(this.paymentCustomer)
  },
  data () {
    return {
      loading: true,
      form: {},
      creating: false,
      updating: false,
      removing: false,
      formCountryOptions: [{ label: 'Deutschland', value: 'DE' }]
    }
  },
  methods: {
    async update () {
      try {
        this.updating = true
        await this.updatePaymentCustomer({ id: this.form.id, data: this.form })
        this.$vfm.hide('settings-payment-customer-edit')
        this.$toasted.success('Abrechnungsinformationen gespeichert', { icon: 'fas fa-save' })
        this.updating = false
        this.getPaymentCustomers()
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.updating = false
      }
    },
    ...mapActions({
      getPaymentCustomers: 'paymentCustomers/fetchList',
      updatePaymentCustomer: 'paymentCustomers/replace'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.name && this.form.email && this.form.line1 && this.form.city && this.form.postalCode && this.form.country)
    },
    formCountry: {
      get () {
        return this.formCountryOptions.find(countryOption => countryOption.value === this.form.country)
      },
      set (country) {
        this.form.country = country ? country.value : null
      }
    }
  }
}
</script>
