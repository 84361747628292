<template>

  <base-form>

    <template #form>

      <div class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="user"/>
            Profil
          </div>
          <div class="group-subtitle">Lege einen Namen, eine E-Mail und ein Passwort dieses Benutzers fest.</div>
        </div>
        <div class="group-fields">
          <BaseInput v-if="type === 'edit'"
                     v-model="form.user.name"
                     :placeholder="$t('users.form.name')"
                     icon="user"
                     type="text"
                     :disabled="type === 'edit'"/>

          <BaseInput v-model="form.user.email"
                     :placeholder="$t('users.form.email')"
                     icon="envelope"
                     type="text"
                     :disabled="type === 'edit'"
                     data-v-step="users-form-email"/>
        </div>
      </div>

      <div v-if="type === 'edit'" class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="image"/>
            Foto
          </div>
          <div class="group-subtitle">Lade ein Foto für diesen Benutzer hoch.</div>
        </div>
        <div class="group-fields">
          <label>{{ $t('users.form.picture') }}</label>
          <vue-dropzone id="dropzone"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                        :useCustomSlot="true"
                        @vdropzone-mounted="dropzoneMounted"
                        @vdropzone-success="success">
            <font-awesome-icon icon="cloud-upload-alt"/>
            {{ $t('users.form.pictureMessage') }}
          </vue-dropzone>
        </div>
      </div>

      <div class="group"
           :class="{'last': type !== 'edit'}">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="shield-alt"/>
            Berechtigung
          </div>
          <div class="group-subtitle">
            Lege eine Berechtigung für diesen Benutzer fest.
            <ul>
              <li><span>Administrator</span> Workspace verwalten</li>
              <li><span>Manager</span> Projekte erstellen</li>
              <li><span>Redakteure</span> Beiträge erstellen</li>
              <li><span>Kunden</span> Beiträge freigeben</li>
              <li><span>Gäste</span> Lesezugriff</li>
            </ul>
          </div>
        </div>

        <div class="group-fields">

          <div class="multiselect-wrapper" data-v-step="users-form-role">
            <label>{{ $t('users.form.role') }}</label>
            <vue-multiselect v-model="role"
                         :options="options"
                         :placeholder="$t('users.form.role')"
                         :showLabels="false"
                         label="label"
                         track-by="value">
              <template #placeholder>
                <font-awesome-icon icon="shield-alt"/>
                <span>Auswählen</span>
              </template>
              <template #option="props">
                <font-awesome-icon :icon="props.option.icon" class="option__image"/>
                {{ props.option.label }}
              </template>
              <template #singleLabel="props">
                <font-awesome-icon :icon="props.option.icon" class="option__image"/>
                {{ props.option.label }}
              </template>
            </vue-multiselect>
          </div>
        </div>
      </div>

      <div class="group" v-if="type === 'edit'">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="bell"/>
            Benachrichtigungen
          </div>
          <div class="group-subtitle">Lege die Slack ID für diesen Benutzer fest.</div>
        </div>
        <div class="group-fields">
          <BaseInput v-model="form.slackId"
                     placeholder="Slack ID"
                     :icon="['fab', 'slack']"
                     type="text"/>
        </div>
      </div>

      <div class="group last" v-if="type === 'edit'">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="check-square"/>
            Status
          </div>
          <div class="group-subtitle">Archiviere diesen Benutzer, falls er nicht mehr Teil deines Teams ist.</div>
        </div>
        <div class="group-fields">
          <BaseCheckbox v-if="type === 'edit'"
                        id="active"
                        v-model="form.active"
                        :placeholder="$t('users.form.active')"/>
        </div>
      </div>

    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :label="$t('button.save')"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  type="submit"
                  @click="update"/>

      <ButtonText v-if="type === 'create'"
                  :disabled="checkForm"
                  :label="$t('button.add')"
                  :loading="creating"
                  icon="plus"
                  type="submit"
                  @click="create"
                  data-v-step="users-form-add"/>
    </template>
  </base-form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseForm from '@/components/base/form/BaseForm'
import { cloneDeep } from 'lodash'
import BaseInput from '@/components/base/input/BaseInput'
import BaseCheckbox from '@/components/base/checkbox/BaseCheckbox'
import vue2Dropzone from 'vue2-dropzone'

export default {
  name: 'UsersForm',
  components: { BaseCheckbox, BaseInput, BaseForm, VueDropzone: vue2Dropzone },
  props: {
    type: String,
    workspaceUser: {
      type: Object,
      default () {
        return {
          user: {
            id: null
          },
          role: null
        }
      }
    }
  },
  created () {
    this.form = cloneDeep(this.workspaceUser)
    this.fetchData()
  },
  data () {
    return {
      loading: true,
      form: {
        user: {},
        role: null
      },
      creating: false,
      updating: false,
      removing: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/users/' + this.workspaceUser.user.id + '/picture',
        acceptedFiles: 'image/jpeg, image/png',
        maxFiles: 1,
        autoProcessQueue: false,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': '',
          'X-Requested-With': ''
        },
        init () {
          this.on('addedfiles', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
          this.on('maxfilesexceeded', function (file) {
            this.removeAllFiles()
            this.addFile(file)
          })
        }
      },
      options: [
        { label: this.$t('role.admin'), value: 5, icon: 'user-shield' },
        { label: this.$t('role.manager'), value: 4, icon: 'user-tie' },
        { label: this.$t('role.editor'), value: 3, icon: 'user-edit' },
        { label: this.$t('role.customer'), value: 2, icon: 'user-check' },
        { label: this.$t('role.guest'), value: 1, icon: 'user-plus' }
      ]
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = false
      } catch (error) {
        console.log(error.response)
      }
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createUser({ data: this.form })
        this.form = response.data
        // if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
        //   this.$refs.myVueDropzone.setOption('url', process.env.VUE_APP_API_URL + '/users/' + this.form.user.id + '/picture')
        //   this.$refs.myVueDropzone.processQueue()
        // } else {
        window.dataLayer.push({ event: 'user_created' })
        this.$vfm.hide('users-create')
        this.$toasted.success('Benutzer hinzugefügt', { icon: 'fas fa-plus' })
        this.creating = false
      } catch (error) {
        this.creating = false
        this.$toasted.error(error.response.data.detail)
      }
    },
    async update () {
      try {
        this.updating = true
        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
          this.$refs.myVueDropzone.processQueue()
        } else {
          await this.updateUser({ id: this.form.id, data: this.form })
          this.$vfm.hide('users-edit')
          this.$toasted.success('Benutzer gespeichert', { icon: 'fas fa-save' })
          this.updating = false
        }
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteUser({ id: this.form.id })
        this.$vfm.hide('users-edit')
        this.$toasted.success('Benutzer entfernt', { icon: 'fas fa-trash-alt' })
        this.removing = false
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.removing = false
      }
    },
    dropzoneMounted () {
      if (this.form.user.pictureName) {
        this.$refs.myVueDropzone.manuallyAddFile({
          name: this.form.user.pictureName,
          type: this.form.user.pictureMimeType,
          size: this.form.user.pictureSize
        }, process.env.VUE_APP_API_URL + this.form.user.pictureUrl)
      }
    },
    async success (file, response) {
      this.$vfm.hideAll()
      this.$toasted.success('Benutzer gespeichert', { icon: 'fas fa-save' })
      this.creating = false
      this.updating = false
      await this.getWorkspaceUser({ id: this.form.id })
    },
    ...mapActions({
      getWorkspaceUser: 'workspaceUsers/fetchSingle',
      createUser: 'workspaceUsers/create',
      updateUser: 'workspaceUsers/replace',
      deleteUser: 'workspaceUsers/destroy'
    })
  },
  computed: {
    role: {
      get () {
        return this.options.find(option => option.value === this.form.role)
      },
      set (role) {
        this.form.role = role.value
      }
    },
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.form.user.email)
    },
    checkForm () {
      return !(this.validEmail && this.form.role)
    },
    ...mapGetters({
      projectById: 'projects/byId',
      users: 'users/list',
      customers: 'users/customers',
      managers: 'users/managers',
      currentUser: 'auth/currentUser'
    })
  }
}
</script>
