<template>

  <base-form>
    <template #form>

      <div class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="building"/>
            Informationen
            <ButtonText v-if="paymentCustomer"
                        icon="pen"
                        label="Bearbeiten"
                        @click="editPaymentCustomer"/>
          </div>
          <div class="group-subtitle">Ändere deine Abrechnungsinformationen wie deinen Namen, deine Adresse oder deine
            Steuernummer.
          </div>

        </div>
        <div class="group-fields">
          <BaseInput v-if="paymentCustomer"
                     v-model="paymentCustomer.name"
                     placeholder="Unternehmen"
                     icon="building"
                     type="text"
                     disabled/>
          <BaseInput v-if="paymentCustomer"
                     v-model="paymentCustomer.email"
                     placeholder="E-Mail"
                     icon="envelope"
                     type="email"
                     disabled/>
          <BaseInput v-if="paymentCustomer"
                     v-model="paymentCustomer.line1"
                     placeholder="Rechnungsadresse"
                     icon="home"
                     type="text"
                     disabled/>
          <BaseInput v-if="paymentCustomer"
                     v-model="paymentCustomer.city"
                     placeholder="Stadt"
                     icon="city"
                     type="text"
                     disabled/>
          <BaseInput v-if="paymentCustomer"
                     v-model="paymentCustomer.postalCode"
                     placeholder="Postleitzahl"
                     icon="city"
                     type="text"
                     disabled/>
          <BaseInput v-if="paymentCustomer"
                     v-model="paymentCustomer.country"
                     placeholder="Land"
                     icon="globe"
                     type="text"
                     disabled/>
          <BaseInput v-if="paymentCustomer"
                     v-model="paymentCustomer.taxId"
                     placeholder="Steuer-ID"
                     icon="university"
                     type="text"
                     disabled/>
        </div>
      </div>

      <div class="group">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="credit-card"/>
            <div>Zahlungsmethode</div>

            <ButtonText v-if="paymentMethod"
                        icon="pen"
                        label="Bearbeiten"
                        @click="editPaymentMethod"/>
            <ButtonText v-if="!paymentMethods.length"
                        icon="plus"
                        label="Hinzufügen"
                        @click="createPaymentMethod"/>
          </div>
          <div class="group-subtitle">Ändere deine Zahlungsmethode oder füge eine neue hinzu.</div>
        </div>

        <div class="group-fields">
          <div v-if="!paymentMethods.length" class="subtitle">Bitte füge eine Zahlungsmethode hinzu!</div>
          <vue-multiselect v-model="paymentMethod"
                       :options="paymentMethods"
                       :showLabels="false"
                       :multiple="false"
                       :disabled="type === 'edit'"
                       placeholder="Auswählen"
                       track-by="id"
                       @input="onPaymentMethodInput">
            <template #singleLabel="props">
              <font-awesome-icon :icon="paymentMethodIcon(props.option)"/>
              <span>{{ paymentMethodLabel(props.option) }}</span>
            </template>
            <template #option="props">
              <font-awesome-icon :icon="paymentMethodIcon(props.option)"/>
              {{ paymentMethodLabel(props.option) }}
            </template>
          </vue-multiselect>
        </div>
      </div>

      <div class="group last">
        <div class="group-left">
          <div class="group-title">
            <font-awesome-icon icon="sync"/>
            Abonnement
          </div>
          <div class="group-subtitle">Dein gewähltes Abonnement und die Kosten auf einen Blick. oder gib einen
            Gutscheincode ein.
          </div>
        </div>

        <div class="group-fields">
          <BaseInput v-if="price"
                     v-model="subscriptionForm.title"
                     placeholder="Abonnement"
                     icon="rocket"
                     type="text"
                     disabled/>

          <BaseInput v-if="price"
                     v-model="priceLabel"
                     placeholder="Preis"
                     icon="euro-sign"
                     type="text"
                     disabled/>

          <BaseInput v-if="price"
                     v-model="taxLabel"
                     placeholder="Umsatzsteuer (19%)"
                     icon="university"
                     type="text"
                     disabled/>

          <BaseInput v-if="price"
                     v-model="subscriptionForm.promoCode"
                     placeholder="Promo Code"
                     icon="gift"
                     type="text"
                     :disabled="type === 'edit'"/>
          <div class="group-title">
            Gesamtsumme: {{ totalLabel }}
          </div>
          <div class="group-subtitle">Dein Abonnement wird automatisch erneuert und ist monatlich kündbar.</div>

        </div>
      </div>

    </template>

    <template #action>
      <ButtonText v-if="type === 'create'"
                  :disabled="checkForm"
                  :loading="creating"
                  icon="arrows-rotate"
                  :label="`${subscription.title} ${$t('button.subscribe')}`"
                  :save="true"
                  type="submit"
                  @click="create"/>

      <ButtonText v-if="type === 'update'"
                  :disabled="checkForm"
                  :loading="updating"
                  icon="arrows-rotate"
                  :label="`Auf ${subscription.title} ändern`"
                  :save="true"
                  type="submit"
                  @click="update"/>

      <ButtonText v-if="type === 'edit'"
                  :loading="removing"
                  :save="true"
                  :icon="subscription.cancelAtPeriodEnd ? 'check-circle' : 'trash'"
                  :label="subscription.cancelAtPeriodEnd ? `${subscription.title} ${$t('button.proceed')}` : `${subscription.title} ${$t('button.cancel')}`"
                  type="submit"
                  :remove="!subscription.cancelAtPeriodEnd"
                  @click="remove"/>

    </template>

  </base-form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseForm from '@/components/base/form/BaseForm'
import { cloneDeep } from 'lodash'
import BaseInput from '@/components/base/input/BaseInput'
import { loadStripe } from '@stripe/stripe-js'

export default {
  name: 'SettingsPaymentSubscriptionForm',
  props: {
    type: String,
    subscription: {
      type: Object,
      default () {
        return {
          priceId: null
        }
      }
    }
  },
  components: { BaseInput, BaseForm },
  created () {
    this.subscriptionForm = cloneDeep(this.subscription)

    if (!this.subscriptionForm.defaultPaymentMethod) {
      // set default payment method
      this.paymentMethod = this.paymentMethods.find(pm => pm.default)
      if (this.paymentMethod) {
        this.subscriptionForm.defaultPaymentMethod = this.paymentMethod.id
      }
    } else {
      // set paymentmethod from subscription
      this.paymentMethod = this.paymentMethods.find(pm => pm.id === this.subscriptionForm.defaultPaymentMethod)
    }

    if (this.type === 'create' || this.type === 'update') this.initStripe()
  },
  data () {
    return {
      loading: true,
      creating: false,
      updating: false,
      removing: false,
      subscriptionForm: {
        defaultPaymentMethod: null
      },
      stripe: null,
      elements: null,
      paymentMethod: null
    }
  },
  methods: {
    async initStripe () {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createSubscription({ data: this.subscriptionForm })
        this.form = response.data
        await this.confirmPayment()
        this.$vfm.hide('settings-payment-subscription-create')
        this.$toasted.success('Abonnement gestartet', { icon: 'fas fa-arrows-rotate' })
        this.creating = false
        this.getSubscriptions()
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            value: this.totalValue,
            tax: this.taxValue,
            currency: 'EUR',
            items: [{ item_name: this.subscriptionForm.title, price: this.priceValue, quantity: 1 }]
          }
        })
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.creating = false
      }
    },
    async confirmPayment () {
      if (this.paymentMethod.type === 'card') {
        await this.stripe.confirmCardPayment(this.form.clientSecret, {
          payment_method: this.paymentMethod.id
        })
      }
      if (this.paymentMethod.type === 'sepa_debit') {
        await this.stripe.confirmSepaDebitPayment(this.form.clientSecret, {
          payment_method: this.paymentMethod.id
        })
      }
    },
    async update () {
      try {
        this.updating = true
        await this.updateSubscription({ id: this.subscriptionForm.id, data: this.subscriptionForm })
        this.$vfm.hide('settings-payment-subscription-update')
        this.$toasted.success(`Abonnement auf ${this.subscriptionForm.title} geändert`, { icon: 'fas fa-check-circle' })
        this.updating = false
        this.getSubscriptions()
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            value: this.totalValue,
            tax: this.taxValue,
            currency: 'EUR',
            items: [{ item_name: this.subscriptionForm.title, price: this.priceValue, quantity: 1 }]
          }
        })
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteSubscription({ id: this.subscriptionForm.id })
        this.$vfm.hide('settings-payment-subscription-edit')
        if (!this.subscriptionForm.cancelAtPeriodEnd) this.$toasted.success('Abonnement wird gekündigt', { icon: 'fas fa-trash-alt' })
        if (this.subscriptionForm.cancelAtPeriodEnd) this.$toasted.success('Abonnement wird fortgesetzt', { icon: 'fas fa-check-circle' })
        this.removing = false
        this.getSubscriptions()
      } catch (error) {
        this.$toasted.error(error.response.data.detail)
        this.removing = false
      }
    },
    editPaymentMethod () {
      this.$vfm.hideAll()
      this.$vfm.show({ component: 'SettingsPaymentMethodEdit', bind: { paymentMethod: this.paymentMethod } })
    },
    createPaymentMethod () {
      this.$vfm.hideAll()
      this.$vfm.show({ component: 'SettingsPaymentMethodCreate' })
    },
    editPaymentCustomer () {
      this.$vfm.hideAll()
      this.$vfm.show({ component: 'SettingsPaymentCustomerEdit', bind: { paymentCustomer: this.paymentCustomer } })
    },
    paymentMethodLabel (paymentMethod) {
      let label
      if (paymentMethod.type === 'card') {
        label = `${paymentMethod.cardType} ${paymentMethod.cardNumber}`
      }
      if (paymentMethod.type === 'sepa_debit') {
        label = `SEPA ${paymentMethod.iban}`
      }
      if (paymentMethod.default) {
        label += ' (Standard)'
      }
      return label
    },
    paymentMethodIcon (paymentMethod) {
      if (paymentMethod.type === 'card') return ['fab', 'cc-' + paymentMethod.cardType]
      if (paymentMethod.type === 'sepa_debit') return 'university'
    },
    onPaymentMethodInput (paymentMethod) {
      if (paymentMethod) {
        this.subscriptionForm.defaultPaymentMethod = paymentMethod.id
      }
    },
    ...mapActions({
      getSubscriptions: 'paymentSubscriptions/fetchList',
      createSubscription: 'paymentSubscriptions/create',
      updateSubscription: 'paymentSubscriptions/replace',
      deleteSubscription: 'paymentSubscriptions/destroy'
    })
  },
  computed: {
    checkForm () {
      return !(this.paymentMethod && this.price)
    },
    price: {
      get () {
        return this.subscriptionForm.priceId ? this.priceById(this.subscriptionForm.priceId) : null
      },
      set (price) {
        this.subscriptionForm.priceId = price.id
      }
    },
    priceValue () {
      return `${(this.price.unitAmount / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    },
    priceLabel () {
      return this.priceValue + '€ / Monat'
    },
    taxValue () {
      return `${(this.price.unitAmount * 0.19 / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    },
    taxLabel () {
      return this.taxValue + '€'
    },
    totalValue () {
      return `${(this.price.unitAmount * 1.19 / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    },
    totalLabel () {
      return this.totalValue + '€'
    },
    paymentCustomer () {
      return this.paymentCustomers.find(paymentCustomer => paymentCustomer)
    },
    ...mapGetters({
      subscriptions: 'paymentSubscriptions/list',
      prices: 'paymentPrices/list',
      priceById: 'paymentPrices/byId',
      paymentMethods: 'paymentMethods/list',
      paymentCustomers: 'paymentCustomers/list'
    })
  }
}
</script>
