<template>

  <base-main>
    <DashboardBar/>
    <DashboardGrid/>
    <projects-create/>
    <projects-edit/>
  </base-main>

</template>

<script>
import store from '@/store'
import BaseMain from '@/components/base/BaseMain'
import DashboardBar from '@/components/dashboard/bar/DashboardBar'
import DashboardGrid from '@/components/dashboard/grid/DashboardGrid'
import ProjectsEdit from '@/components/projects/ProjectsEdit'
import ProjectsCreate from '@/components/projects/ProjectsCreate'

export default {
  name: 'DashboardView',
  components: {
    ProjectsCreate,
    ProjectsEdit,
    BaseMain,
    DashboardBar,
    DashboardGrid
  },
  created () {
    document.title = 'Dashboard'
  },
  beforeRouteEnter (to, from, next) {
    if (!store.getters['auth/hasSuperAdminRole']) {
      next({ name: 'projects' })
    } else next()
  }
}
</script>
