<template>

  <div class="textarea-wrapper">
    <font-awesome-icon :icon="icon"/>
    <textarea :placeholder="placeholder"
              :value="value"
              required
              @input="$emit('input', $event.target.value)">
        </textarea>
    <label>{{ placeholder }}</label>
  </div>

</template>

<script>
export default {
  name: 'BaseTextarea',
  props: {
    type: String,
    placeholder: String,
    icon: String,
    value: String
  }
}
</script>

<style lang="scss">

/* Textarea */
textarea {
  background: none;
  resize: none;
  outline: 0;
  -webkit-appearance: none;
  overflow: scroll;
  border: none;
  font: inherit;
  scrollbar-width: none;
}

textarea::-webkit-scrollbar {
  display: none;
}

.textarea-wrapper {
  position: relative;
  margin-bottom: 15px;
  height: 100px;
}

.textarea-wrapper textarea {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  line-height: 1.5;
  font-size: 0.875rem;
  border: 1px solid $text-color-4;
  border-radius: 5px;
  //transition: 0.3s all ease;
  padding: 8px 16px 16px 36px;
}

.textarea-wrapper textarea:focus {
  border: 2px solid $text-color-1;
}

.textarea-wrapper svg {
  position: absolute;
  left: 12px;
  top: 14px;
  color: $text-color-3;
}

.textarea-wrapper textarea + label {
  position: absolute;
  bottom: 64px;
  left: 34px;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 0 4px;
  opacity: 0;
}

.textarea-wrapper textarea:focus + label {
  color: $text-color-1;
  bottom: 94px;
  font-size: 0.75rem;
  background-color: $white;
  opacity: 1;
}

.textarea-wrapper textarea:valid + label {
  bottom: 94px;
  font-size: 0.75rem;
  background-color: $white;
  opacity: 1;
}

#app.dark {

  .textarea-wrapper svg {
    color: $gray-500;
  }

  .textarea-wrapper textarea {
    border-color: $gray-800;
    color: $white;
  }

  .textarea-wrapper textarea:focus {
    border-color: $white;
  }

  .textarea-wrapper textarea + label {
    background-color: $dark-2;
  }

}

</style>
