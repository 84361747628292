<template>

  <form @submit.prevent="">

    <slot name="form"/>

    <div class="action">
      <slot name="action"/>
    </div>

  </form>

</template>

<script>

export default {
  name: 'BaseForm'
}
</script>

<style lang="scss">

section.modal form .subtitle {
  font-size: 0.75rem;
  line-height: 1.2;
}

section.modal form .group {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
  padding-bottom: 15px;
  gap: 20px;
  border-bottom: 1px solid $text-color-4;
}

section.modal form .group.last {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

section.modal form .group .group-left {
  display: flex;
  flex-flow: column nowrap;
  width: 250px;
  flex-grow: 0;
}

section.modal form .group .group-title {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  color: $text-color-1;
  line-height: 1.2;
  margin-bottom: 5px;
  height: 30px;
}

section.modal form .group .group-title svg {
  margin-right: 10px;
  color: $teal-500;
}

section.modal form .group .group-title .button {
  margin-left: auto;
}

section.modal form .group .group-title .button span {
  display: none;
}

section.modal form .group .group-subtitle {
  font-size: 0.875rem;
  color: $text-color-2;
  line-height: 1.25;
}

section.modal form .group .group-subtitle ul {
  line-height: 1;
  margin-top: 5px;
}

section.modal form .group .group-subtitle ul li span {
  display: inline-flex;
  font-weight: 500;
  background-color: $gray-100;
  font-size: 0.75rem;
  padding: 1px 2px;
  border-radius: 2px;
  margin-bottom: 4px;
}

section.modal form .group .group-fields {
  width: 300px;
  flex-grow: 1;
}

#app.dark {
  section.modal form .group {
    border-bottom-color: $dark-3;
  }

  section.modal form .group .group-title {
    color: white;
  }

  section.modal form .group .group-subtitle {
    color: $gray-500;
  }

  section.modal form .group .group-subtitle ul li span {
    background-color: $dark-3;
  }

}

</style>
