<template>

  <vue-final-modal
    name="project-posting-item-datetime-modal"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': '400px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs">
    <section class="modal">
      <div class="top">
        <div class="title">{{ $t('postings.date') }}</div>
        <font-awesome-icon icon="times" @click="close"/>
      </div>

      <v-date-picker
        v-model="datetimeString"
        :is-dark="darkmodeStatus"
        :minute-increment="10"
        color="teal"
        is-expanded
        is24hr
        mode="datetime"
        title-position="left"
        :locale="$i18n.locale">
      </v-date-picker>

      <div class="action">
        <ButtonText :loading="updating"
                    icon="save"
                    :label="$t('button.save')"
                    type="submit"
                    @click="update"/>
      </div>
    </section>

  </vue-final-modal>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import moment from 'moment'

export default {
  name: 'ProjectPostingItemDatetimeModal',
  data () {
    return {
      form: {},
      updating: false
    }
  },
  props: {
    posting: Object
  },
  created () {
    this.form = cloneDeep(this.posting)
  },
  methods: {
    async update () {
      try {
        this.updating = true
        await this.updatePosting({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-posting-item-datetime-modal')
        this.$toasted.success('Datum geändert', { icon: 'fas fa-calendar-day' })
        await this.getPosting({ id: this.form.id })
        this.updating = false
      } catch (error) {
        this.updating = false
        this.$toasted.error(error.response.data.detail)
      }
    },
    ...mapActions({
      updatePosting: 'postings/replace',
      getPosting: 'postings/fetchSingle'
    })
  },
  computed: {
    datetimeString: {
      get () {
        return moment(this.form.datetime).toDate()
      },
      set (value) {
        this.form.datetime = moment(value).toISOString()
      }
    },
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
