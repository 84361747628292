import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'paymentInvoices',
  urlRoot: '/payment_invoices',
  getters: {
    paid: (state) => {
      return Object.values(state.entities).filter(invoice => invoice.status === 'paid')
    },
    open: (state) => {
      return Object.values(state.entities).filter(invoice => invoice.status === 'open')
    }
  }
})
