import axios from 'axios'

export default {
  namespaced: true,
  state: {
    stats: {},
    resource: 'stats',
    urlRoot: '/stats'
  },
  mutations: {
    fetchStatsSuccess (state, payload) {
      state.stats = payload.data
    }
  },
  actions: {
    async fetch ({ commit, state }) {
      try {
        const response = await axios.get(state.urlRoot)
        commit('fetchStatsSuccess', response)
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {
    stats: state => state.stats
  }
}
