<template>
  <base-list title="Trends" subtitle="Heute für Deutschland" icon="fire-alt" :count="trends.length" :loading="loading">
    <base-list-card>
      <VirtualList style="height: 600px; overflow-y: auto;"
                   :data-key="'name'"
                   :data-sources="trends"
                   :data-component="dashboardTrendItem"
                   :page-mode="false"
                   :estimate-size="50"/>
    </base-list-card>
  </base-list>

</template>

<script>
import BaseList from '@/components/base/list/BaseList'
import DashboardTrendItem from '@/components/dashboard/grid/trends/DashboardTrendItem'
import VirtualList from 'vue-virtual-scroll-list'
import { mapActions, mapGetters } from 'vuex'
import BaseListCard from '@/components/base/list/BaseListCard'

export default {
  name: 'DashboardTrends',
  components: { BaseListCard, BaseList, VirtualList },
  data () {
    return {
      loading: true,
      dashboardTrendItem: DashboardTrendItem
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        this.trends.length || await this.fetchTrends()
        this.loading = false
      } catch (e) {
        console.error(e)
      }
    },
    ...mapActions({
      fetchTrends: 'dashboard/fetchTrends'
    })
  },
  computed: {
    ...mapGetters({
      trends: 'dashboard/trends'
    })
  }
}
</script>
