<template>

  <header>

    <div class="wrapper">

      <div class="left">

        <div class="logo">
          <img src="/static/konnektor_bildmarke_white.svg"/>
        </div>

        <nav class="nav">
          <!--          <router-link v-if="hasEditorRole" :to="{ name: 'dashboard' }" class="nav-item nav-item-dashboard">-->
          <!--            {{ $t('dashboard.title') }}-->
          <!--          </router-link>-->
          <router-link :to="{ name: 'projects' }" class="nav-item nav-item-projects">
            {{ $t('projects.title') }}
          </router-link>
          <router-link v-if="hasManagerRole" :to="{ name: 'users' }" class="nav-item nav-item-users">
            {{ $t('users.title') }}
          </router-link>

          <router-link v-if="hasSuperAdminRole" :to="{ name: 'admin' }" class="nav-item nav-item-users">
            Admin
          </router-link>
        </nav>

      </div>

      <div class="right">
        <router-link v-if="workspace && (workspace.subscription === 'FREE' || workspace.subscription === 'BASIC')"
                     :to="{ name: 'settingsPayment' }"
                     class="projects">
          {{ projectMessage }}
        </router-link>

        <div v-if="currentUser" class="user">
          <img v-if="currentUser.pictureName" class="picture" :src="picture" alt=""/>
          <span v-else class="circle">{{ initial }}</span>
          <div class="text">{{ name }}</div>
        </div>

        <div class="dropdown">
          <div class="dropdown-item dropdown-item-user">
            <img v-if="currentUser.pictureName" class="picture" :src="picture" alt=""/>
            <font-awesome-icon v-else icon="user"/>
            <div v-if="currentUser">
              <div class="title">{{ name }}</div>
              <div class="subtitle">{{ currentUser.email }}</div>
            </div>
          </div>
          <span class="divider"/>
          <div v-if="currentUser && currentUser.workspaceUsers.length > 1" class="dropdown-item-workspaces">
            <div class="dropdown-item"
                 :class="{active: isCurrentWorkspace(workspaceUser)}"
                 v-for="workspaceUser in currentUser.workspaceUsers"
                 :key="workspaceUser.id"
                 @click="changeWorkspace(workspaceUser.workspace)">
              <img v-if="workspaceUser.workspace.logoName" class="picture" :src="logo(workspaceUser.workspace)" alt=""/>
              <font-awesome-icon v-else icon="building"/>
              {{ workspaceUser.workspace.name }}
              <!--              <span v-if="isCurrentWorkspace(workspaceUser)">Aktiv</span>-->
            </div>
            <span class="divider"/>
          </div>
          <div class="dropdown-item">
            <router-link :to="{ name: 'settings' }" class="nav-item nav-item-settings">
              <font-awesome-icon icon="cog"/>
              {{ $t('settings.title') }}
            </router-link>
          </div>
          <div class="dropdown-item">
            <router-link :to="{ name: 'logout' }" class="nav-item">
              <font-awesome-icon icon="sign-out-alt"/>
              {{ $t('button.logout') }}
            </router-link>
          </div>
        </div>

      </div>
    </div>

  </header>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { first, startCase } from 'lodash'

export default {
  name: 'TheHeader',
  methods: {
    isCurrentWorkspace (workspaceUser) {
      return this.currentUser.workspace.id === workspaceUser.workspace.id
    },
    async changeWorkspace (workspace) {
      if (this.currentUser.workspace.id !== workspace.id) {
        await this.updateUser({ id: this.user.id, data: { ...this.user, workspace: '/workspaces/' + workspace.id } })
        this.$router.go(0)
      }
    },
    logo (workspace) {
      return `${process.env.VUE_APP_API_URL}${workspace.logoUrl}`
    },
    ...mapActions({
      updateUser: 'users/replace'
    })
  },
  computed: {
    initial () {
      return first(this.name)
    },
    picture () {
      return process.env.VUE_APP_API_URL + this.currentUser.pictureUrl
    },
    name () {
      return this.currentUser ? startCase(this.currentUser.name) : ''
    },
    icon () {
      switch (this.currentWorkspaceUser.role) {
        case 5:
          return 'user-shield'
        case 4:
          return 'user-tie'
        case 3:
          return 'user-edit'
        case 2:
          return 'user-check'
        case 1:
          return 'user-plus'
        default:
          return null
      }
    },
    workspace () {
      return this.workspaceById(this.currentUser.workspace.id)
    },
    user () {
      return this.userById(this.authUser.id)
    },
    projectMessage () {
      let maxProjects
      switch (this.workspace.subscription) {
        case 'FREE':
          maxProjects = 1
          break
        case 'BASIC':
          maxProjects = 3
          break
        default:
      }
      const projectsLeft = maxProjects - this.projects.length
      if (projectsLeft > 1) {
        return `${projectsLeft} Projekte übrig`
      } else if (projectsLeft === 1) {
        return `${projectsLeft} Projekt übrig`
      } else {
        return 'Upgrade für weitere Projekte'
      }
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      currentUser: 'auth/currentUser',
      currentWorkspaceUser: 'auth/currentWorkspaceUser',
      hasSuperAdminRole: 'auth/hasSuperAdminRole',
      hasAdminRole: 'auth/hasAdminRole',
      hasManagerRole: 'auth/hasManagerRole',
      hasEditorRole: 'auth/hasEditorRole',
      projects: 'projects/list',
      users: 'users/list',
      userById: 'users/byId',
      workspaceById: 'workspaces/byId'
    })
  }
}
</script>

<style lang="scss">

header {
  position: sticky;
  top: 0;
  background-color: $teal-500;
  backdrop-filter: saturate(180%) blur(20px);
  z-index: 900;
  padding-bottom: 20px;
  height: 50px;
}

header .wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px;
}

header .wrapper .left {
  display: flex;
  overflow-y: hidden;
}

header .wrapper .left .logo {
  display: flex;
  align-items: center;
  color: $white;
  font-size: 1.5rem;
  margin: 22px 10px 15px 0;
  font-family: 'Lovelo', sans-serif;
}

header .wrapper .left .logo img {
  height: 24px;
}

header .wrapper .left .nav {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
}

header .wrapper .left .nav::-webkit-scrollbar {
  display: none;
}

header .wrapper .left .nav .nav-item {
  padding: 13px 0 0 0;
  margin-right: 15px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  color: $teal-300;
  transition: all 300ms cubic-bezier(.25, .8, .25, 1);
  border-top: 3px solid transparent;
}

header .wrapper .left .nav .nav-item:hover {
  color: $white;
}

header .wrapper .left .nav .nav-item.router-link-active {
  color: $white;
  border-top-color: $white;
}

header .wrapper .left .nav .nav-item.router-link-active:hover {
  color: $white;
}

header .wrapper .left .nav .nav-item.router-link-exact-active {
  color: $white;
  border-top-color: $white;
}

/* Header > Right */
header .wrapper .right {
  display: flex;
  align-items: center;
  position: relative;
}

/* Header > Right > */
header .wrapper .right .projects {
  display: flex;
  align-items: center;
  color: $white;
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 10px;
  height: 50px;
  text-align: right;
}

/* Header > Right > User */
header .wrapper .right .user {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  height: 50px;
}

header .wrapper .right .user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

header .wrapper .right .user svg {
  color: $white;
}

header .wrapper .right .user span.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: $teal-500;
  font-size: 1.25rem;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: $teal-50;
}

header .wrapper .right .user .text {
  font-weight: 500;
  font-size: 0.875rem;
  margin-left: 5px;
  color: $white;
}

/* Header > Right > Dropdown */
header .wrapper .right .dropdown {
  position: absolute;
  margin-top: 45px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: $box-shadow-2;
  display: none;
  z-index: 1000;
}

header .wrapper .right .user:hover + .dropdown,
header .wrapper .right .user + .dropdown:hover {
  display: block;
  top: 0;
  right: 0;
}

header .wrapper .right .dropdown span.divider {
  display: flex;
  background-color: $gray-200;
  height: 1px;
  width: 100%;
}

header .wrapper .right .dropdown .dropdown-item {
  display: flex;
  align-items: center;
  padding: 2px 14px 2px 11px;
  margin: 10px 0;
  font-size: 0.875rem;
  color: $text-color-3;
  font-weight: 500;
  border-left: 3px solid transparent;
}

header .wrapper .right .dropdown .dropdown-item img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}

header .wrapper .right .dropdown .dropdown-item svg {
  margin-right: 5px;
  color: $text-color-3;
  transition: 0.3s all ease;
}

/* Header > Right > Dropdown > User */
header .wrapper .right .dropdown .dropdown-item.dropdown-item-user {
  color: $text-color-1;
  transition: 0.3s all ease;
}

header .wrapper .right .dropdown .dropdown-item.dropdown-item-user svg {
  color: $text-color-1;
}

header .wrapper .right .dropdown .dropdown-item.dropdown-item-user .title {
  font-weight: 500;
  color: $text-color-1;
}

header .wrapper .right .dropdown .dropdown-item.dropdown-item-user .title svg {
  color: $text-color-1;
}

header .wrapper .right .dropdown .dropdown-item.dropdown-item-user .subtitle {
  font-size: 0.75rem;
  white-space: nowrap;
  color: $text-color-2;
  font-weight: 400;
}

/* Header > Right > Dropdown > Workspaces */
header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item {
  transition: 0.3s all ease;
  cursor: pointer;
}

header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item img {
  opacity: 0.5;
  transition: 0.3s all ease;
}

header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item:hover,
header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item:hover svg {
  color: $text-color-1;
}

header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item:hover img {
  opacity: 1;
}

header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item.active,
header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item.active svg {
  color: $text-color-1;
}

header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item.active img {
  opacity: 1;
}

header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item.active {
  border-left: 3px solid $teal-500;
}

header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item span {
  font-weight: 500;
  display: inline-flex;
  border-radius: 2px;
  background-color: $teal-500;
  color: $white;
  padding: 1px 4px;
  margin-left: 4px;
}

/* Header > Right > Dropdown > Links */
header .wrapper .right .dropdown .dropdown-item .nav-item {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-left: 0;
  padding-top: 0;
  transition: 0.3s all ease;
}

header .wrapper .right .dropdown .dropdown-item .nav-item:hover,
header .wrapper .right .dropdown .dropdown-item .nav-item:hover svg {
  color: $text-color-1
}

header .wrapper .right .dropdown .dropdown-item .nav-item.router-link-active,
header .wrapper .right .dropdown .dropdown-item .nav-item.router-link-active svg {
  color: $text-color-1
}

@media only screen and (max-width: 768px) {

  header .wrapper .right .nav-item span {
    display: none;
  }

  header .wrapper .right .user .text {
    display: none;
  }

  header .wrapper .right .nav-item svg {
    display: block;
  }

}

#app.dark {

  header {
    background-color: $dark-1;
  }

  header .wrapper .left .logo {
    color: $white
  }

  header .wrapper .left .nav .nav-item {
    color: $gray-500;
  }

  header .wrapper .left .nav .nav-item:hover {
    color: $gray-300;
  }

  header .wrapper .left .nav .nav-item.router-link-active {
    color: $gray-300;
    border-top-color: $gray-500;
  }

  header .wrapper .left .nav .nav-item.router-link-exact-active {
    color: $white;
    border-top-color: $white;
  }

  header .wrapper .right .nav-item {
    color: $gray-500;
  }

  header .wrapper .right .nav-item:hover {
    color: $gray-300;
  }

  header .wrapper .right .nav-item.router-link-active {
    color: $white;
    border-top-color: $white;
  }

  header .wrapper .right .user span.circle {
    background-color: $gray-800;
    color: $white;
  }

  header .wrapper .right .user span.workspace {
    color: $gray-500;
  }

  header .wrapper .right .dropdown {
    background-color: $dark-2;
  }

  header .wrapper .right .dropdown span.divider {
    background-color: $gray-800;
  }

  header .wrapper .right .dropdown .dropdown-item.dropdown-item-user svg {
    color: $white;
  }

  header .wrapper .right .dropdown .dropdown-item.dropdown-item-user .title {
    color: $white;
  }

  header .wrapper .right .dropdown .dropdown-item .subtitle {
    color: $gray-500
  }

  header .wrapper .right .dropdown .dropdown-item .nav-item svg {
    color: $gray-500
  }

  header .wrapper .right .dropdown .dropdown-item .nav-item:hover svg {
    color: $white;
  }

  /* Header > Right > Dropdown > Workspaces */
  header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item,
  header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item svg {
    color: $gray-500;
  }

  header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item:hover,
  header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item:hover svg {
    color: $white;
  }

  header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item.active,
  header .wrapper .right .dropdown .dropdown-item-workspaces .dropdown-item.active svg {
    color: $white;
  }

  /* Header > Right > Dropdown > Links */
  header .wrapper .right .dropdown .dropdown-item .nav-item.router-link-active,
  header .wrapper .right .dropdown .dropdown-item .nav-item.router-link-active svg {
    color: $white;
  }

}

</style>
