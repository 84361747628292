<template>

  <button :class="classObject"
          :disabled="disabled || loading"
          :type="type"
          class="button"
          @click="$emit('click')">

    <font-awesome-icon v-if="!loading && icon" :icon="icon"/>
    <clip-loader v-if="loading"
                 :color="loaderColor"
                 :loading="loading"
                 size="16px"/>

    <span v-if="label">{{ label }}</span>
  </button>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    icon: [String, Array],
    design: String,
    disabled: Boolean,
    label: String,
    loading: Boolean,
    // color: String,
    save: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classObject () {
      return {
        'button-text': this.design === 'text',
        'button-contained': this.design === 'contained',
        'button-save': this.save,
        'button-remove': this.remove
      }
    },
    loaderColor () {
      if (this.darkmodeStatus) {
        if (this.loading) {
          return '#9E9E9E'
        }
        return '#FFFFFF'
      }
      if (this.loading) {
        return 'rgba(0,0,0,0.38)'
      }
      if (this.save) {
        return '#009688'
      }
      if (this.remove) {
        return '#E57373'
      }
      return 'rgba(0,0,0,0.87)'
    },
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>

<style lang="scss">

button {
  font: inherit;
}

.button {
  display: flex;
  align-items: center;
  appearance: none;
  height: 36px;
  padding: 10px;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  border: 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: $text-color-1;
  outline: 0;
  user-select: none;
  transition: all 300ms cubic-bezier(.25, .8, .25, 1);
  white-space: nowrap;
}

.button:hover {
  cursor: pointer;
}

// Disabled
.button[disabled] {
  color: $text-color-3 !important;
  cursor: default;
}

.button[disabled] svg {
  color: $text-color-3 !important;
}

// Icon
.button svg {
  font-size: 0.875rem;
  color: $text-color-1;
}

.button span {
  margin-left: 5px;
}

// Save
.button.button-save {
  color: $teal-500;
}

.button.button-save svg {
  color: $teal-500;
}

// Remove
.button.button-remove {
  color: $red-300;
}

.button.button-remove svg {
  color: $red-300;
}

@media only screen and (max-width: 768px) {

  button {
    min-width: inherit;
  }

}

#app.dark {

  .button {
    color: $white;
  }

  .button svg {
    color: $white;
  }

  .button[disabled] {
    color: $gray-500 !important;
  }

  .button[disabled] svg {
    color: $gray-500 !important;
  }
}

</style>
