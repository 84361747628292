<template>

    <div class="input-wrapper">
        <font-awesome-icon :icon="icon"/>
        <input :placeholder="placeholder"
               :step="step"
               :type="type"
               :value="value"
               :disabled="disabled"
               :maxlength="maxlength"
               :autocomplete="autocomplete"
               @input="$emit('input', $event.target.value)"/>
        <label>
            {{ placeholder }}
        </label>
    </div>

</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    type: String,
    step: String,
    placeholder: String,
    icon: [String, Array],
    value: [String, Number],
    maxlength: {
      type: Number,
      default: 524288
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  }
}
</script>

<style lang="scss">

/* Textfield Floating label */
.input-wrapper {
    position: relative;
    height: 40px;
    margin-bottom: 16px;
    display: flex;
    flex-flow: row nowrap;
}

.input-wrapper svg.svg-inline--fa {
    position: absolute;
    left: 12px;
    top: 13px;
    color: $text-color-3;
    font-size: 0.875rem;
}

/* Textfield Floating label > Input */
.input-wrapper input {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 12px;
    padding-bottom: 11px;
    padding-left: 32px;
    width: 100%;
    height: 40px;
    color: $text-color-1;
    border: 1px solid $text-color-4;
    border-radius: 4px;
}

.input-wrapper input:focus {
    border-color: $text-color-1;
}

.input-wrapper input + label {
    position: absolute;
    bottom: 11px;
    left: 20px;
    color: $text-color-3;
    cursor: text;
    font-size: 1rem;
    transition: all 0.2s ease;
    pointer-events: none;
    opacity: 0;
    background-color: $white;
    padding: 0 4px;
}

.input-wrapper input:focus + label {
    left: 8px;
    bottom: 34px;
    font-size: 0.75rem;
    color: $text-color-1;
    opacity: 1;
}

.input-wrapper input:not(:placeholder-shown) + label {
    left: 8px;
    bottom: 34px;
    font-size: 0.75rem;
    color: $text-color-3;
    opacity: 1;
    background-color: $white;
}

.input-wrapper input:focus:not(:placeholder-shown) + label {
    color: $text-color-1;
}

.input-wrapper input:disabled {
    color: $text-color-3;
}

.input-wrapper input:disabled + label {
    bottom: 34px;
    font-size: 0.75rem;
    color: $text-color-3;
    opacity: 1;
}

#app.dark {

    .input-wrapper input {
        color: white;
        border-color: $gray-800;
    }

    .input-wrapper svg.svg-inline--fa {
        color: $gray-500;
    }

    .input-wrapper input + label {
        color: $gray-700;
        background-color: $dark-2;
    }

    .input-wrapper input:focus + label {
        color: $white;
    }

    .input-wrapper input:focus {
        border-color: $gray-500;
    }

    .input-wrapper input:disabled {
      color: $gray-700;
    }

  //.input-wrapper input:disabled + label {
  //  bottom: 32px;
  //  font-size: 0.75rem;
  //  color: $text-color-3;
  //  opacity: 1;
  //}

}

</style>
