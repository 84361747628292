import createCrudModule from '@/utils/createCrudModule'
import _ from 'lodash'
import i18n from '@/utils/i18n'

export default createCrudModule({
  resource: 'postings',
  urlRoot: '/postings',
  state: {
    filter: {
      search: '',
      platforms: [],
      media: [],
      status: [],
      archivedStatus: { icon: 'check-square', label: i18n.t('postings.top.status.active'), value: false },
      sortOrder: 'desc'
    },
    defaultPlatforms: [
      { label: 'Facebook', value: 'facebook', icon: ['fab', 'facebook'] },
      { label: 'Instagram', value: 'instagram', icon: ['fab', 'instagram'] },
      { label: 'LinkedIn', value: 'linkedin', icon: ['fab', 'linkedin'] },
      { label: 'YouTube', value: 'youtube', icon: ['fab', 'youtube'] },
      { label: 'TikTok', value: 'tiktok', icon: ['fab', 'tiktok'] },
      { label: 'X', value: 'x', icon: ['fab', 'x-twitter'] },
      { label: 'WhatsApp', value: 'whatsapp', icon: ['fab', 'whatsapp'] },
      { label: 'Google', value: 'google', icon: ['fab', 'google'] },
      { label: 'Xing', value: 'xing', icon: ['fab', 'xing'] },
      { label: 'Snapchat', value: 'snapchat', icon: ['fab', 'snapchat'] },
      { label: 'Spotify', value: 'spotify', icon: ['fab', 'spotify'] },
      { label: 'Pinterest', value: 'pinterest', icon: ['fab', 'pinterest'] },
      { label: 'Twitch', value: 'twitch', icon: ['fab', 'twitch'] },
      { label: 'Reddit', value: 'reddit', icon: ['fab', 'reddit'] }
    ]
  },
  getters: {
    filter: state => state.filter,
    byProjectName: (state, getters, rootState, rootGetters) => name => {
      const postings = Object.values(state.entities).filter(posting => posting.project === encodeURI('/projects/' + name))
      // TODO: filter postings for only status 2 or 3
      if (rootGetters['auth/hasCustomerRole'] || rootGetters['auth/hasGuestRole']) {
        return postings.filter(posting => {
          const latestRevision = posting.revisions.reduce((prev, cur) => prev.createdAt > cur.createdAt ? prev : cur, 0)
          return (latestRevision.status === 2 || latestRevision.status === 3)
        })
      }
      return postings
    },
    filteredByProjectName: (state, getters) => name => {
      return _.orderBy(getters.byProjectName(name), 'datetime', state.filter.sortOrder)
        .filter(posting => {
          const revision = posting.revisions.reduce((prev, cur) => prev.createdAt > cur.createdAt ? prev : cur, 0)
          if (state.filter.search) {
            return (revision.text ? revision.text.toLowerCase().indexOf(state.filter.search.toLowerCase()) > -1 : false) || (revision.comment ? revision.comment.toLowerCase().indexOf(state.filter.search.toLowerCase()) > -1 : false)
          }
          return posting
        })
        .filter(posting => !state.filter.platforms.length ||
          state.filter.platforms.some(filterPlatform => filterPlatform.value === posting.platform))
        .filter(posting =>
          // no filter is set
          !state.filter.media.length ||
          // filter is set
          state.filter.media.some(filterMedia =>
            // filter for no material isset
            (!filterMedia.value && !posting.media.length) ||
            // loop through posting media
            (posting.media.length && posting.media.some(postingMedia => {
              if (Array.isArray(filterMedia.value)) {
                return filterMedia.value.some(filterMediaValue => filterMediaValue === postingMedia.mimeType)
              } else {
                return filterMedia.value === postingMedia.mimeType
              }
            }))
          )
        )
        .filter(posting => !state.filter.status.length ||
          state.filter.status.some(filterStatus => {
            return filterStatus.value === posting.revisions.reduce((prev, cur) => prev.createdAt > cur.createdAt ? prev : cur, 0).status
          }))
        .filter(posting => state.filter.archivedStatus.value === posting.isDraft)
    },
    activeByProjectName: (state, getters) => name => {
      return getters.filteredByProjectName(name).filter(posting => !posting.isDraft)
    },
    draftsByProjectName: (state, getters) => name => {
      return getters.filteredByProjectName(name).filter(posting => posting.isDraft)
    },
    adBudgetByProjectName: (state, getters) => name => {
      return getters.byProjectName(name)
        .filter(posting => posting.adBudget)
        .map(posting => posting.adBudget)
        .reduce((cur, acc) => cur + acc, 0)
    },
    latestRevisionByProjectName: (state, getters) => name => {
      return getters.byProjectName(name)
        .map(posting => posting.revisions.reduce((prev, cur) => prev.createdAt > cur.createdAt ? prev : cur, 0))
        .reduce((prev, cur) => prev.createdAt > cur.createdAt ? prev : cur, 0)
    },
    defaultPlatforms: state => state.defaultPlatforms,
    tourCompleted: (state, getters, rootState, rootGetters) => rootGetters['auth/currentUser'].tours.postings
  }
})
