<template>

  <base-create :title="$t('users.form.add')"
               name="users-create"
               v-bind="$attrs"
               v-on="$listeners">
    <users-form type="create"/>
  </base-create>

</template>

<script>
import BaseCreate from '@/components/base/form/BaseCreate'
import UsersForm from '@/components/users/UsersForm'

export default {
  name: 'UsersCreate',
  components: { BaseCreate, UsersForm }
}
</script>
