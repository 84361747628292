<template>
  <base-form>
    <template #form>
      <BaseInput v-model="name"
                 placeholder="Plattform"
                 icon="share-alt"
                 type="text"/>

      <div class="multiselect-wrapper">
        <label>Icon Style</label>
        <vue-multiselect
          v-model="form.icon[0]"
          :hide-selected="true"
          :option-height="40"
          :options="['fas', 'far', 'fab']"
          placeholder="Icon-Style"
          :showLabels="false"
          :custom-label="iconStyleLabel">
          <template #placeholder>
            <font-awesome-icon icon="icons"/>
            <span>Icon-Style</span>
          </template>
        </vue-multiselect>
      </div>
      <BaseInput v-model="form.icon[1]"
                 placeholder="Icon Name"
                 :icon="iconPreview"
                 type="text"/>
      <div class="subtitle">
        Beispiel: Icon Style: <strong style="font-weight: bold">Solid</strong> | Icon-Name: <strong
        style="font-weight: bold">envelope</strong>
        <br>
        <br>
        <a href="https://fontawesome.com/icons" target="_blank">
          <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square"/>
          Icons auf Font Awesome
        </a>
      </div>
    </template>

    <template #action>
      <ButtonText v-if="type === 'edit'"
                  :loading="removing"
                  :remove="true"
                  :save="true"
                  icon="trash"
                  :label="$t('button.remove')"
                  type="button"
                  @click="remove"/>
      <ButtonText v-if="type === 'edit'"
                  :disabled="checkForm"
                  :loading="updating"
                  :save="true"
                  icon="save"
                  :label="$t('button.save')"
                  type="submit"
                  @click="update"/>
      <ButtonText v-if="type === 'create'"
                  :disabled="checkForm"
                  :loading="creating"
                  :save="true"
                  icon="add"
                  :label="$t('button.add')"
                  type="submit"
                  @click="create"/>
    </template>

  </base-form>
</template>

<script>
import BaseForm from '@/components/base/form/BaseForm'
import BaseInput from '@/components/base/input/BaseInput'
import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SettingsWorkspacePlatformForm',
  components: { BaseInput, BaseForm },
  props: {
    type: String,
    platform: Object,
    index: Number
  },
  data () {
    return {
      form: null,
      creating: false,
      updating: false,
      removing: false
    }
  },

  created () {
    this.form = cloneDeep(this.platform)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        this.workspace.platforms.push(this.form)
        await this.updateWorkspace({ id: this.workspace.id, data: this.workspace })
        this.$vfm.hide('settings-workspace-platform-create')
        this.$toasted.success('Plattform hinzugefügt', { icon: 'fas fa-share-alt' })
        this.creating = false
      } catch (error) {
        this.$toasted.error('Fehler beim Hinzufügen der Plattform', { icon: 'fas fa-share-alt' })
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        this.workspace.platforms[this.index] = this.form
        await this.updateWorkspace({ id: this.workspace.id, data: this.workspace })
        this.$vfm.hide('settings-workspace-platform-edit')
        this.$toasted.success('Plattform gespeichert', { icon: 'fas fa-share-alt' })
        this.updating = false
      } catch (error) {
        this.$toasted.error('Fehler beim Speichern der Plattform', { icon: 'fas fa-share-alt' })
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        this.workspace.platforms = this.workspace.platforms.filter((platform, index) => index !== this.index)
        await this.updateWorkspace({ id: this.workspace.id, data: this.workspace })
        this.$vfm.hide('settings-workspace-platform-edit')
        this.$toasted.success('Plattform entfernt', { icon: 'fas fa-share-alt' })
        this.removing = false
      } catch (e) {
        this.$toasted.error('Fehler beim Entfernen der Plattform', { icon: 'fas fa-share-alt' })
        this.removing = false
      }
    },
    iconStyleLabel (style) {
      switch (style) {
        case 'fas':
          return 'Solid'
        case 'far':
          return 'Regular'
        case 'fab':
          return 'Brands'
      }
    },
    ...mapActions({
      updateWorkspace: 'workspaces/replace'
    })
  },
  computed: {
    workspace () {
      return this.workspaceById(this.currentUser.workspace.id)
    },
    name: {
      get () {
        return this.form.label
      },
      set (value) {
        this.form.label = value
        this.form.value = value.toLowerCase()
      }
    },
    iconPreview () {
      return this.form.icon[1] ? [this.form.icon[0], this.form.icon[1]] : 'icons'
    },
    checkForm () {
      return (!this.form.name && !this.form.label && !this.form.icon.length)
    },
    ...mapGetters({
      workspaceById: 'workspaces/byId',
      currentUser: 'auth/currentUser'
    })
  }
}
</script>
