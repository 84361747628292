<template>

  <section class="wrapper-login">

    <div class="logo">
      <img src="/static/konnektor_wortmarke_white.svg"/>
    </div>

    <div class="top">
      <div class="title">Passwort festlegen</div>
    </div>

    <div class="card">
      <form @submit.prevent="sendPasswordResetConfirm">
        <BaseInput v-model="password"
                   icon="key"
                   placeholder="Neues Passwort"
                   type="password"/>

        <ButtonText :disabled="!password"
                    :loading="loading"
                    label="Festlegen"
                    type="submit"/>
      </form>
    </div>

    <div class="footer">
      <router-link :to="{ name: 'login' }">Zurück zum Login</router-link>
    </div>

  </section>

</template>

<script>
import { mapState } from 'vuex'
import BaseInput from '@/components/base/input/BaseInput'
import axios from 'axios'

export default {
  name: 'ResetPasswordConfirm',
  components: { BaseInput },
  data () {
    return {
      loading: false,
      success: null,
      password: null
    }
  },
  methods: {
    async sendPasswordResetConfirm () {
      try {
        this.loading = true
        await axios.post('/reset_password/confirm', {
          passwordResetToken: this.$route.params.token,
          plainPassword: this.password
        })
        this.$toasted.success('Passwort festgelegt!', { icon: 'fas fa-key' })
        this.loading = false
        this.success = true
        await this.$router.push({ name: 'login' })
      } catch (error) {
        this.loading = false
        this.$toasted.error(error.response.data, { icon: 'fas fa-exclamation-circle' })
      }
    },
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email)
    }
  },
  computed: {
    ...mapState(['app', 'auth'])
  }
}
</script>
